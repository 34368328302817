import React, { useEffect, useState } from "react";
import { classNames } from "primereact/utils";
import { Dropdown } from "primereact/dropdown";
import { AiFillSound } from "react-icons/ai";
import { useFetchLocution } from "../../../hooks/useLocution";

export const Entities = ({
  entidades,
  setEntidades,
  handleSimpleEntidades,
  genericError,
}) => {
  const [options, setOptions] = useState(null);
  const [loading, setLoading] = useState(null);

  const filtrarOptions = (opt) => {
    return opt.map((elemento) => ({
      id: elemento.id,
      audioName: elemento.audioName,
      path: elemento.path,
    }));
  };

  const _filterLocution = {
    rows: 8000,
  };
  const fetchLocution = useFetchLocution(_filterLocution);
  useEffect(() => {
    if (fetchLocution.data) {
      setOptions(filtrarOptions(fetchLocution.data));
    }
    setLoading(fetchLocution.isLoading);
  }, [fetchLocution.data]);

  const setLocution = (value, key) => {
    const _entidades = [...entidades];
    const _index = _entidades.findIndex((entidad) => entidad.key === key);
    if (_index !== -1) {
      _entidades[_index] = {
        ..._entidades[_index],
        locution: value,
      };
      setEntidades(_entidades);
    }
    handleSimpleEntidades(_entidades);
  };

  return (
    <ul className="entidades">
      {entidades &&
        entidades.map((entitie) => (
          <li key={entitie.key}>
            <div className="entitie">
              <span className="label">{entitie.nombre}</span>
              <AiFillSound className="icon" />
              <div className="audio">
                <Dropdown
                  value={
                    (options &&
                      entitie &&
                      entitie.locution &&
                      options.find((op) => op.id === entitie.locution.id)) ||
                    null
                  }
                  options={options}
                  onChange={(e) => setLocution(e.value, entitie.key)}
                  placeholder={!loading ? "Selecciona" : "Cargando..."}
                  optionLabel="audioName"
                  className={classNames({
                    "p-invalid": entitie.locution === null,
                  })}
                  style={{ maxWidth: "300px" }}
                />
              </div>
            </div>
            {entitie.locution === null && (
              <small className="p-error block" style={{ textAlign: "end" }}>
                {genericError}
              </small>
            )}
          </li>
        ))}
    </ul>
  );
};
