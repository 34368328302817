import React from "react";
import { Button } from "primereact/button";
import { useNodeTypesStore } from "../../store/nodeTypesStore";

export const LeftToolBar = ({ addNode }) => {
  const { nodeTypes } = useNodeTypesStore((state) => state);

  return (
    <div className="tool-bar">
      {nodeTypes.map((n) => (
        <Button
          key={n.name}
          tooltip={n.name}
          onClick={() => addNode(n.type)}
          style={{
            backgroundColor: n.color,
            backgroundImage: `url(${n.icon})`,
            backgroundSize: "cover",
            opacity: n.enabled ? "100%" : "40%",
            width: "30px",
            height: "30px",
          }}
          disabled={!n.enabled}
        />
      ))}
    </div>
  );
};
