import { useQuery } from "@tanstack/react-query";
import { viewTypes } from "../common/types/views.type";
import locutionService from "../services/locution.service";

const fetchLocution = async (filter) => {
  const { vnVoicebot: viewName, vfVerVoiceBot: viewFunction } = viewTypes;
  const userName = localStorage.getItem("userName");

  const response = await locutionService.ObtenerLocuciones(
    filter,
    userName,
    viewName,
    viewFunction
  );
  return response.data.locutions;
};

export function useFetchLocution(filter) {
  return useQuery({
    queryKey: ["locutions"],
    queryFn: () => fetchLocution(filter),
    retry: false,
    refetchOnWindowFocus: false,
  });
}
