import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { genId } from "../../utils/idGenerator";
import { useVoicebotStore } from "../../store/voicebotStore";

export const EntrenaInput = ({
  id,
  handleElementChange,
  name,
  required,
  genericError,
}) => {
  const { elements, getElement } = useVoicebotStore((state) => state);

  const [options, setOptions] = useState(null);
  const [items, setItems] = useState([]);
  const contentEditableRef = useRef(null);

  useEffect(() => {
    getElement(id)?.data[name] && setInitial();
  }, []);

  const setInitial = () => {
    const _itemsRaw = elements
      .find((element) => element.data.id === id)
      ?.data[name].map((item) => item);
    setItems(_itemsRaw);
  };

  /** */
  useEffect(() => {
    setOptions(filterNodes);
  }, []);

  const filterNodes = () => {
    const nodes = elements.filter((element) => element.group === "nodes");
    if (nodes.length === 0) {
      return [];
    }
    const formatNodes = nodes.map((node) => {
      return {
        name: node.data.name,
        id: node.data.id,
      };
    });

    return formatNodes;
  };
  /** */

  const handleInsertSpan = (text, tipo) => {
    if (tipo === "nodo") {
      setNodo("");
    } else {
      setVariable("");
    }

    const span = document.createElement("span");
    span.style.color = tipo === "nodo" ? "#23547B" : "#805B36";
    span.style.backgroundColor = tipo === "nodo" ? "#B3E5FC" : "#FFD8B2";
    span.style.padding = "0 5px 2px 5px";
    span.style.borderRadius = "3px";
    span.textContent = text.trim();
    span.contentEditable = "false";

    span.setAttribute("data-type", tipo);

    const spaceNode = document.createTextNode("\u00A0");
    const newHtml = `${contentEditableRef.current.innerHTML}${span.outerHTML}${spaceNode.textContent}`;

    contentEditableRef.current.innerHTML = newHtml;

    moveCursorToEnd();
    contentEditableRef.current.focus();
  };

  const moveCursorToEnd = () => {
    const range = document.createRange();
    const selection = window.getSelection();
    const contentEditable = contentEditableRef.current;

    range.selectNodeContents(contentEditable);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
  };

  const [variable, setVariable] = useState("");
  const [nodo, setNodo] = useState("");
  const [nodesItem, setNodesItem] = useState([]);

  /** */
  const aplanarItem = (htmlText) => {
    let _plainText = htmlText.replace(
      /<span[^>]*>(.*?)<\/span>/g,
      function (match, p1) {
        var tipo = match.match(/data-type="([^"]+)"/);
        var contenido = p1.trim();
        if (tipo && tipo[1] === "variable") {
          return "{var." + contenido + "}";
        } else if (tipo && tipo[1] === "nodo") {
          return "{nodo." + contenido + "}";
        } else {
          return contenido;
        }
      }
    );
    _plainText = _plainText.replace(/&nbsp;/g, " ");

    return _plainText;
  };

  /** */
  const recheckNodes = (content) => {
    const extraerNodos = (content) => {
      const regex = /{nodo\.(.*?)}/g;
      let result = [];
      let match;
      while ((match = regex.exec(content)) !== null) {
        result.push(match[1]);
      }
      return result;
    };
    const _nodesItem = nodesItem.filter((item) =>
      extraerNodos(content).includes(item.name)
    );
    return _nodesItem;
  };

  const handleAddItem = () => {
    const content = contentEditableRef.current.innerHTML.trim();
    const _string = aplanarItem(content);

    if (content !== "") {
      const _id = genId();
      const _nodesItem = recheckNodes(_string);

      const _item = {
        format: content,
        string: _string,
        id: _id,
        nodes: _nodesItem,
      };
      setItems([...items, _item]);
      setNodesItem([]);

      handleElementChange(name, [...items, _item], id);
      contentEditableRef.current.innerHTML = "";
      moveCursorToEnd();
    }
  };

  const handleRemoveItem = (_id) => {
    const _newItems = items.filter((item) => item.id !== _id);
    setItems(_newItems);

    if (_newItems.length === 0) {
      handleElementChange(name, "", id);
    } else {
      handleElementChange(name, _newItems, id);
    }
  };

  const handleKeyDown = (event, text, tipo) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleInsertSpan(text, tipo);
    }
  };

  const handleNodo = (value) => {
    setNodo(value);

    const _nodeObj = {
      idNode: value.id,
      name: value.name,
    };

    setNodesItem((_nodes) => [..._nodes, _nodeObj]);
    handleInsertSpan(value.name, "nodo");
  };

  return (
    <>
      <div
        className="entrenamiento"
        style={{
          borderColor:
            required && getElement(id)?.data[name] === ""
              ? "#f44336"
              : "#ced4da",
        }}
      >
        <div
          className="texto-editable"
          ref={contentEditableRef}
          contentEditable
          dangerouslySetInnerHTML={{ __html: "" }}
        />

        <div className="form">
          <div className="inputs">
            <div className="grupo">
              <div
                className="circulo"
                style={{
                  marginLeft: 0,
                  marginRight: "4px",
                  backgroundColor: "#FFD8B2",
                }}
              ></div>
              <InputText
                value={variable}
                onChange={(e) => setVariable(e.target.value)}
                placeholder="Variable"
                autoComplete="off"
                onKeyDown={(e) => handleKeyDown(e, variable, "variable")}
              />
              <Button
                className="plus"
                icon="pi pi-plus"
                onClick={() => handleInsertSpan(variable, "variable")}
                disabled={variable === ""}
              />
            </div>
            <div className="grupo" style={{ marginLeft: "16px" }}>
              <div
                className="circulo"
                style={{
                  marginLeft: 0,
                  marginRight: "4px",
                  backgroundColor: "#B3E5FC",
                }}
              ></div>
              <Dropdown
                style={{ width: "130px" }}
                value={nodo}
                options={options}
                onChange={(e) => handleNodo(e.value)}
                optionLabel="name"
                placeholder="Nodo"
              />
            </div>
          </div>
          <div>
            <Button label="Agregar" onClick={() => handleAddItem()} />
          </div>
        </div>

        <ListaItems items={items} handleRemoveItem={handleRemoveItem} />
      </div>
      {required && getElement(id)?.data[name] === "" && (
        <small className="p-error block">{genericError}</small>
      )}
    </>
  );
};

const ListaItems = ({ items, handleRemoveItem }) => {
  return (
    <ul className="lista-entrenamientos">
      {items.map((item, index) => (
        <li key={item.id}>
          <span
            className="item"
            dangerouslySetInnerHTML={{ __html: item.format }}
          />
          <Button
            icon="pi pi-trash"
            className="trash p-button-danger"
            onClick={() => handleRemoveItem(item.id)}
          />
        </li>
      ))}
    </ul>
  );
};
