import http from "../common/components/http-common";

export const downloadTemplate = (
  idSurvey: string,
  surveyName: string,
  userName: string,
  viewName: string,
  viewFunction: string
) => {
  const headers = { userName, viewName, viewFunction };

  return http
    .request({
      url: `/graph/idSurvey/${idSurvey}/downloadTemplate`,
      method: "get",
      headers: headers,
      responseType: "blob", //important
    })
    .then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `template-${surveyName}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
};
