import React, { useState } from "react";
import { Dropdown, InputText } from "primereact";
import { FilterWrapper } from "./_filterPanel/FilterWrapper";
import { styleEstado } from "../utils/option.util";

export const FilterPanel = ({
  lazyParams,
  setLazyParams,
  initParams,
  users,
}) => {
  const initFilters = {
    nombre: "",
    activo: "",
    tipo: "",
    userId: "",
  };

  const formatUsers = (users) => {
    return users.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
  };

  const optionsTipo = [
    {
      label: "SALIENTE",
      value: "saliente",
    },
    {
      label: "ENTRANTE",
      value: "entrante",
    },
  ];

  const optionsStatus = [
    {
      label: "Activo",
      value: true,
    },
    {
      label: "Inactivo",
      value: false,
    },
  ];

  const itemTemplate = (data) => {
    return (
      <span
        className={
          "customer-badge status-" +
          styleEstado(data.label === "Activo") +
          " text-status"
        }
      >
        {data.label}
      </span>
    );
  };

  const selectedTemplate = (option, props) => {
    if (option) {
      return (
        <span
          className={
            "customer-badge status-" +
            styleEstado(option.label === "Activo") +
            " text-status"
          }
        >
          {option.label}
        </span>
      );
    }
    return <span>{props.placeholder}</span>;
  };

  const [filters, setFilters] = useState(initFilters);

  const filtros = [
    {
      title: "Nombre",
      filtro: "nombre",
      input: (
        <InputText
          className="filter-text"
          value={filters.nombre}
          placeholder="Ingresa el nombre"
        />
      ),
    },
    {
      title: "Estado",
      filtro: "activo",
      input: (
        <div className="select-container">
          <Dropdown
            value={lazyParams.activo}
            options={optionsStatus}
            onChange={(e) => handleChange("activo", e.value)}
            placeholder="Elige un estado"
            valueTemplate={selectedTemplate}
            itemTemplate={itemTemplate}
          />
        </div>
      ),
    },
    {
      title: "Tipo",
      filtro: "tipo",
      input: (
        <div className="select-container">
          <Dropdown
            value={lazyParams.tipo}
            options={optionsTipo}
            onChange={(e) => handleChange("tipo", e.value)}
            placeholder="Elige un tipo"
          />
        </div>
      ),
    },
    {
      title: "Usuario",
      filtro: "userId",
      input: (
        <div className="select-container">
          <Dropdown
            value={lazyParams.userId}
            options={formatUsers(users)}
            onChange={(e) => handleChange("userId", e.value)}
            placeholder="Elige un usuario"
          />
        </div>
      ),
    },
  ];

  /** */

  const handleChange = (name, value) => {
    setFilters((prevEstado) => ({
      ...prevEstado,
      [name]: value,
    }));
    setLazyParams((prevEstado) => ({
      ...prevEstado,
      [name]: value,
    }));
  };

  return (
    <FilterWrapper
      lazyParams={lazyParams}
      setLazyParams={setLazyParams}
      initFilters={initFilters}
      filtros={filtros}
      initParams={initParams}
      filters={filters}
      setFilters={setFilters}
      handleChange={handleChange}
    />
  );
};
