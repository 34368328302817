import React from "react";
import { Button, Dialog } from "primereact";
import { useVoicebotStore } from "../../store/voicebotStore";

export const DeleteDialog = ({
  deleteDialog,
  hideDeleteDialog,
  deletePreVoicebot,
}) => {
  const { preVoicebot } = useVoicebotStore((state) => state);

  const deleteVoicebotDialogFooter = (
    <>
      <Button
        label="Si"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deletePreVoicebot}
      />
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteDialog}
      />
    </>
  );

  return (
    <Dialog
      visible={deleteDialog}
      header="Borrar voicebot"
      modal
      footer={deleteVoicebotDialogFooter}
      onHide={hideDeleteDialog}
      style={{ width: "400px", maxWidth: "95%" }}
    >
      <div className="flex align-items-center justify-content-center">
        <i
          className="pi pi-exclamation-triangle mr-3"
          style={{ fontSize: "2rem" }}
        />
        {preVoicebot && (
          <span>
            ¿Estas seguro de que quieres eliminar
            <br />
            <b>{preVoicebot.nombre}</b>?
          </span>
        )}
      </div>
    </Dialog>
  );
};
