import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useVoicebotStore } from "../../store/voicebotStore";

export const MensajeInput = ({
  id,
  handleElementChange,
  name,
  required,
  genericError,
}) => {
  const { getElement } = useVoicebotStore((state) => state);
  const contentEditableRef = useRef(null);
  const [variable, setVariable] = useState("");

  useEffect(() => {
    const _value = getElement(id)?.data[name]?.format || "";
    if (contentEditableRef.current) {
      contentEditableRef.current.innerHTML = _value;
    }
  }, [id, name, getElement]);

  const handleInsertSpan = (text) => {
    setVariable("");
    const span = document.createElement("span");
    span.style.color = "#805B36";
    span.style.backgroundColor = "#FFD8B2";
    span.style.padding = "0 5px 2px 5px";
    span.style.borderRadius = "3px";
    span.textContent = text.trim();
    span.contentEditable = "false";
    span.setAttribute("data-type", "variable");

    const spaceNode = document.createTextNode("\u00A0");
    contentEditableRef.current.appendChild(span);
    contentEditableRef.current.appendChild(spaceNode);

    moveCursorToEnd();
    handleInput();
  };

  const moveCursorToEnd = () => {
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(contentEditableRef.current);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
  };

  const handleInput = () => {
    const content = contentEditableRef.current.innerHTML.trim();
    const _mensaje = {
      format: content,
      string: aplanarItem(content),
    };
    handleElementChange(name, _mensaje, id);
  };

  const aplanarItem = (htmlText) => {
    let _plainText = htmlText.replace(/<a[^>]*>(.*?)<\/a>/g, (match, p1) => {
      return p1.trim();
    });
    _plainText = _plainText.replace(
      /<span[^>]*>(.*?)<\/span>/g,
      (match, p1) => {
        const tipo = match.match(/data-type="([^"]+)"/);
        const contenido = p1.trim();
        if (tipo && tipo[1] === "variable") {
          return "{var." + contenido + "}";
        } else {
          return contenido;
        }
      }
    );
    _plainText = _plainText.replace(/&nbsp;/g, " ");

    return _plainText;
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData("text");
    const modifiedText = pastedText.replace(
      /https?:\/\/[^\s/$.?#].[^\s]*/g,
      (url) => `<a href="${url}" target="_blank">${url}</a>`
    );

    const selection = window.getSelection();
    if (!selection.rangeCount) return;

    const range = selection.getRangeAt(0);
    range.deleteContents();

    const tempElement = document.createElement("div");
    tempElement.innerHTML = modifiedText;

    const fragment = document.createDocumentFragment();
    while (tempElement.firstChild) {
      fragment.appendChild(tempElement.firstChild);
    }
    range.insertNode(fragment);
    range.collapse(false);

    selection.removeAllRanges();
    selection.addRange(range);
    handleInput();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const handleKeyDownButton = (event, variable) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleInsertSpan(variable);
    }
  };

  return (
    <>
      <div
        className="entrenamiento"
        style={{
          borderColor:
            required && getElement(id)?.data[name] === ""
              ? "#f44336"
              : "#ced4da",
        }}
      >
        <div
          className="texto-editable"
          ref={contentEditableRef}
          contentEditable
          onInput={handleInput}
          onPaste={handlePaste}
          onKeyDown={handleKeyDown}
          role="textbox"
          tabIndex={0}
          style={{
            padding: "8px",
            border: "1px solid #ced4da",
            borderRadius: "4px",
            minHeight: "94px",
            outline: "none",
          }}
        />
        <div className="form">
          <div className="inputs">
            <div className="grupo">
              <div
                className="circulo"
                style={{
                  marginLeft: 0,
                  marginRight: "4px",
                  backgroundColor: "#FFD8B2",
                }}
              ></div>
              <InputText
                value={variable}
                onChange={(e) => setVariable(e.target.value)}
                placeholder="Variable"
                autoComplete="off"
                onKeyDown={(e) => handleKeyDownButton(e, variable)}
              />
              <Button
                className="plus"
                icon="pi pi-plus"
                onClick={() => handleInsertSpan(variable)}
                disabled={variable === ""}
              />
            </div>
          </div>
        </div>
      </div>
      {required && getElement(id)?.data[name] === "" && (
        <small className="p-error block">{genericError}</small>
      )}
    </>
  );
};
