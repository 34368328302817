import React from "react";
import { MdDeleteForever } from "react-icons/md";
import { AudioPlayer } from "./AudioPlayer";

export const Question = ({ pregunta, eliminarPregunta, getClass }) => {
  return (
    <div className="question">
      {pregunta.map((p) => (
        <span key={p} className={`palabra customer-badge ${getClass(p.value)}`}>
          {p.type === "locution" && <AudioPlayer pregunta={p} />}
          <span>{p.label}</span>
          <button
            className="delete"
            type="button"
            onClick={() => eliminarPregunta(p.id)}
          >
            <MdDeleteForever />
          </button>
        </span>
      ))}
    </div>
  );
};
