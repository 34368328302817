import React from "react";
import { MultiSelect } from "primereact/multiselect";
import { classNames } from "primereact/utils";
import { useVoicebotStore } from "../../../store/voicebotStore";

export const MultiComponent = ({
  id,
  options,
  name,
  handleMultiple,
  loading,
  width,
  required,
  componentType,
}) => {
  const { getElement } = useVoicebotStore((state) => state);

  const element = getElement(id);
  const data = element ? element.data : {};

  const mapMulti = (_value) => {
    if (!Array.isArray(_value)) {
      return [];
    }
    return _value.map((item) => item.value);
  };

  return (
    <MultiSelect
      filter={true}
      style={{ maxWidth: width }}
      value={
        componentType !== "intention"
          ? mapMulti(data[name])
          : mapMulti(data[name]?.multiple || [])
      }
      options={options}
      onChange={(e) => handleMultiple(e.value)}
      placeholder={!loading ? "Selecciona" : "Cargando..."}
      className={classNames({
        "p-invalid": required && componentType !== "intention" && !data[name],
      })}
    />
  );
};
