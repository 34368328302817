import React, { useState } from "react";
import { classNames } from "primereact/utils";
import { Dropdown } from "primereact/dropdown";
import { useGeneralStore } from "../../store/generalStore";
import { useVoicebotStore } from "../../store/voicebotStore";

export const EntityInput = ({
  name,
  required,
  id,
  genericError,
  flechasIni,
  trackearForm,
}) => {
  const {
    elements,
    getElement,
    setElements,
    estados,
    setEstados,
    getNodeTracker,
    updateNodeTracker,
  } = useVoicebotStore((state) => state);

  const { entidades, setEntidades } = useGeneralStore((state) => state);
  const [options, setOptions] = useState(entidades);

  const changeState = (_handler) => {
    const filterElements = (elements, _handler) => {
      const deletedIds = new Set(
        _handler._deletedEdges.map((edge) => edge.data.id)
      );

      return elements.filter((element) => {
        if (deletedIds.has(element.data.id)) {
          return false;
        }

        if (element.data.id === id) {
          element.data[name] = _handler._entidad;
        }

        const _nodeTracker = {
          ...getNodeTracker(id),
          ..._handler._nodeTracker,
        };
        updateNodeTracker(id, _nodeTracker);
        return true;
      });
    };

    const filterEstados = (estados, deletedEdges) => {
      const deletedIds = new Set(deletedEdges.map((edge) => edge.data.id));
      return estados.filter((estado) => !deletedIds.has(estado.flechitaId));
    };

    setElements(filterElements(elements, _handler));
    setEstados(filterEstados(estados, _handler._deletedEdges));

    trackearForm(id);
  };

  /** */
  const manejarFlechas = (_entidad) => {
    const combineFlechas = (f1, f2) => {
      const flechasMap = new Map();
      [...f1, ...f2].forEach((flecha) => {
        flechasMap.set(flecha.value, flecha);
      });
      return Array.from(flechasMap.values());
    };

    const _flechasInt = _entidad.map(({ label }) => ({
      value: label,
      name: label,
    }));
    const _flechas = combineFlechas(flechasIni, _flechasInt);

    const flechasConDisabled = _flechas.map((flecha) => ({
      ...flecha,
      disabled: false,
    }));
    const flechasDisponibles = flechasConDisabled.filter(
      (flecha) => !flecha.disabled
    ).length;

    estados.forEach((estado) => {
      if (id === estado.questionId) {
        flechasConDisabled.forEach((flecha) => {
          if (flecha.value === estado.flechita) {
            flecha.disabled = true;
          }
        });
      }
    });

    const _nodeTracker = {
      flechas: flechasConDisabled,
      disponibles: flechasDisponibles,
    };

    /* edges */
    const filtrarElements = (elements, id, flechas) => {
      const nombresFlechas = flechas.map((flecha) => flecha.name);
      const filteredElements = elements.filter((element) => {
        if (element.group === "edges" && element.data.source === id) {
          return !nombresFlechas.includes(element.data.edgeOption);
        }
        return false;
      });
      return filteredElements;
    };

    const _deletedEdges = filtrarElements(elements, id, _nodeTracker.flechas);

    return {
      _nodeTracker,
      _deletedEdges,
      _entidad: null,
    };
  };

  /*** */
  const handleEntidad = (value) => {
    const _value = options.find((obj) => obj.value === value);
    const _entidad = _value;

    /** */
    let handler = manejarFlechas(_value.options);
    handler._entidad = _entidad;

    changeState(handler);
  };

  return (
    <>
      <Dropdown
        id={name}
        name={name}
        value={getElement(id)?.data[name] && getElement(id)?.data[name].value}
        options={options}
        onChange={(e) => handleEntidad(e.value)}
        required={required}
        placeholder={options ? "Selecciona" : "Cargando..."}
        className={classNames({
          "p-invalid": required && !getElement(id)?.data[name],
        })}
      />
      {required && !getElement(id)?.data[name] && (
        <small className="p-error block">{genericError}</small>
      )}
    </>
  );
};
