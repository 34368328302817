import React from "react";
import { Button, Dialog } from "primereact";
import { useVoicebotStore } from "../../store/voicebotStore";

export const ConfirmationDialog = ({
  toggleDialog,
  hideToggleDialog,
  togglePreVoicebot,
}) => {
  const { preVoicebot } = useVoicebotStore((state) => state);

  const verbo = preVoicebot.activo ? "desactivar" : "activar";
  const deleteLocutionDialogFooter = (
    <>
      <Button
        label="Si"
        icon="pi pi-check"
        className="p-button-text"
        onClick={togglePreVoicebot}
      />
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideToggleDialog}
      />
    </>
  );

  return (
    <Dialog
      visible={toggleDialog}
      header={`${verbo.charAt(0).toUpperCase() + verbo.slice(1)} voicebot`}
      modal
      footer={deleteLocutionDialogFooter}
      onHide={hideToggleDialog}
      style={{ width: "400px", maxWidth: "95%" }}
    >
      <div className="flex align-items-center justify-content-center">
        <i
          className="pi pi-exclamation-triangle mr-3"
          style={{ fontSize: "2rem" }}
        />
        {preVoicebot && (
          <span>
            ¿Estas seguro de que quieres {verbo}
            <br />
            <b>{preVoicebot.nombre}</b>?
          </span>
        )}
      </div>
    </Dialog>
  );
};
