import React, { useEffect, useRef, useState, useCallback } from "react";
import CytoscapeComponent from "react-cytoscapejs";
import cytoscape from "cytoscape";
import edgehandles from "cytoscape-edgehandles";
import { useVoicebotStore } from "../../store/voicebotStore";
import { useGraphStore } from "../../store/graphStore";
import { GraphTools } from "./GraphTools";
cytoscape.use(edgehandles);

const InnerGraph = ({
  selectNode,
  selectEdge,
  addEdge,
  tieneFlechasDisponibles,
}) => {
  const { elements, nodeTrackers, voicebot } = useVoicebotStore(
    (state) => state
  );
  const { drawMode, setDrawMode } = useGraphStore((state) => state);

  const [graphProps, setGraphProps] = useState({});
  const cyRef = useRef(null);

  const handleNodeClick = useCallback(
    (event) => {
      const cy = cyRef.current;
      const clickedNode = event.target;
      const nodeId = clickedNode.id();
      const nodeData = elements.find((elem) => elem.data.id === nodeId);

      cy.nodes().removeClass("selected").style("background-color", "");

      if (nodeData) {
        clickedNode.toggleClass("selected");

        if (clickedNode.hasClass("selected")) {
          clickedNode.style("background-color", "#888");
        }
        selectNode(nodeData);
      }
    },
    [elements, selectNode]
  );

  useEffect(() => {
    if (cyRef.current) {
      const cy = cyRef.current;

      const addedEdges = new Set();

      const addEdgeToGraph = (sourceNodeId, targetNodeId) => {
        const edgeId = `edge-${sourceNodeId}-${targetNodeId}`;

        if (!addedEdges.has(edgeId)) {
          addEdge(sourceNodeId, targetNodeId);
          addedEdges.add(edgeId);
        }
      };

      const getTracker = (_id) => {
        const getNodeTracker = (_id) => {
          const _tracker = nodeTrackers.find((elemento) => elemento.id === _id);
          return _tracker ? _tracker.validForm : false;
        };

        return getNodeTracker(_id);
      };

      cy.elements().remove();
      cy.add(elements);

      cy.style().clear();
      cy.style()
        .fromJson([
          {
            selector: "node",
            style: {
              "background-image": "data(icon)",
              "background-fit": "cover",
              "font-size": "14px",
              "border-color": (ele) => {
                const nodeId = ele.data("id");
                return getTracker(nodeId) ? "transparent" : "#f44336";
              },
              "border-width": (ele) => {
                const nodeId = ele.data("id");
                return getTracker(nodeId) ? "0px" : "1px";
              },
              "background-color": (ele) => {
                return ele.data("color");
              },
              label: (ele) => {
                const id = ele.data("id");
                const name = ele.data("name");
                return id === voicebot.firstQuestion ? `🚩${name}` : name;
              },
            },
          },
          {
            selector: "edge",
            style: {
              "curve-style": "bezier",
              "line-color": "#ccc",
              "target-arrow-shape": "triangle",
              "target-arrow-color": "#ccc",
              label: "data(edgeOption)",
              "font-size": "14px",
              width: 3,
            },
          },
        ])
        .update();

      // manejar clic en el nodo
      cy.on("click", "node", handleNodeClick);

      // manejar clic en el edge
      cy.on("click", "edge", (event) => {
        const clickedEdge = event.target;
        const edgeId = clickedEdge.id();

        const edgeData = elements.find((e) => e.data.id === edgeId);

        if (edgeData) {
          cy.edges().removeClass("selected").style({
            "line-color": "#ccc",
            "target-arrow-color": "#ccc",
          });
          clickedEdge.toggleClass("selected");

          if (clickedEdge.hasClass("selected")) {
            clickedEdge.style({
              "line-color": "#888",
              "target-arrow-color": "#888",
            });
          }

          selectEdge(edgeData);
        }
      });

      let defaults = {
        canConnect: function (sourceNode, targetNode) {
          return !sourceNode.same(targetNode);
        },
        edgeParams: function (sourceNode, targetNode) {
          return {};
        },
        hoverDelay: 150,
        snap: true,
        snapThreshold: 50,
        snapFrequency: 15,
        noEdgeEventsInDraw: true,
        disableBrowserGestures: true,
      };

      const eh = cy.edgehandles(defaults);

      cy.on("ehcomplete", (event, sourceNode, targetNode, addedEdge) => {
        if (!tieneFlechasDisponibles(sourceNode.id())) {
          addedEdge.remove();
          return;
        }

        if (targetNode && targetNode.isNode()) {
          const sourceNodeId = sourceNode.id();
          const targetNodeId = targetNode.id();

          addEdgeToGraph(sourceNodeId, targetNodeId);

          eh.disableDrawMode();
          setDrawMode(false);
        } else {
          console.error("Nodo de destino no válido");
        }
      });

      cyRef.current.eh = eh;
      return () => {
        cy.removeListener("ehcomplete");
      };
    }
  }, [elements, setDrawMode, voicebot.firstQuestion]);

  useEffect(() => {
    setGraphProps({
      style: { width: "100%", height: "100%" },
      cy: (cy) => (cyRef.current = cy),
    });
  }, []);

  useEffect(() => {
    if (cyRef.current && cyRef.current.eh) {
      if (drawMode) {
        cyRef.current.eh.enableDrawMode();
        cyRef.current.autoungrabify(true); // desactiva el drag de nodos
      } else {
        cyRef.current.eh.disableDrawMode();
        cyRef.current.autoungrabify(false); // permite el drag de nodos
      }
    }
  }, [drawMode]);

  const toggleDrawMode = (enabled) => {
    setDrawMode(enabled);
  };

  const handleZoomIn = () => {
    if (cyRef.current) {
      const cy = cyRef.current;
      const currentZoom = cy.zoom();
      cy.zoom(currentZoom + 0.1);
    }
  };

  const handleZoomOut = () => {
    if (cyRef.current) {
      const cy = cyRef.current;
      const currentZoom = cy.zoom();
      cy.zoom(currentZoom - 0.1);
    }
  };

  const repositionGraph = () => {
    if (cyRef.current) {
      const cy = cyRef.current;
      cy.zoom(1);
      cy.pan({ x: -cy.width() / 2, y: -cy.height() / 2 });
      cy.center();
    }
  };

  const repositionGraphToOrigin = () => {
    if (cyRef.current) {
      const cy = cyRef.current;
      cy.zoom(1);
      cy.pan({ x: 0, y: 0 });
    }
  };

  return (
    <div className="inner-graph">
      <GraphTools
        handleZoomIn={handleZoomIn}
        handleZoomOut={handleZoomOut}
        repositionGraph={repositionGraph}
        repositionGraphToOrigin={repositionGraphToOrigin}
        toggleDrawMode={toggleDrawMode}
      />

      <CytoscapeComponent elements={elements} {...graphProps} />
    </div>
  );
};

export default InnerGraph;
