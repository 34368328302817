import { create } from "zustand";

export const useNodeTypesStore = create((set, get) => ({
  nodeTypes: [],

  setNodeTypes: (nodeTypes) => set({ nodeTypes }),

  getNodeType: (type) => {
    const { nodeTypes } = get();
    return nodeTypes.find((node) => node.type === type);
  },
}));
