import React from "react";
import { useGraphStore } from "../../store/graphStore";
import { MdMoreVert, MdCenterFocusStrong } from "react-icons/md";

export const GraphTools = ({
  handleZoomIn,
  handleZoomOut,
  repositionGraph,
  toggleDrawMode,
  repositionGraphToOrigin,
}) => {
  const { drawMode } = useGraphStore((state) => state);
  return (
    <div className="graph-tools">
      <button onClick={handleZoomIn}>
        <i className="pi pi-search-plus"></i>
      </button>

      <button onClick={handleZoomOut}>
        <i className="pi pi-search-minus"></i>
      </button>
      <button onClick={repositionGraphToOrigin}>
        <i className="pi pi-arrows-alt"></i>
      </button>
      <button onClick={repositionGraph}>
        <MdCenterFocusStrong style={{ fontSize: "18px" }} />
      </button>

      <MdMoreVert style={{ color: "#ccc" }} />

      <button
        style={{
          border: !drawMode ? "2px solid #bbb" : "0",
          opacity: !drawMode ? ".9" : "1",
        }}
        onClick={() => toggleDrawMode(false)}
      >
        <i className="pi pi-circle"></i>
      </button>

      <button
        style={{
          border: drawMode ? "2px solid #bbb" : "0",
          opacity: drawMode ? ".9" : "1",
        }}
        onClick={() => toggleDrawMode(true)}
      >
        <i className="pi pi-arrow-up-left"></i>
      </button>
    </div>
  );
};
