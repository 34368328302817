import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { InputSwitch } from "primereact/inputswitch";
import { MultiSelect } from "primereact/multiselect";
import PreguntaInput from "../../inputs/preguntaInput/PreguntaInput";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import SelectInput from "../../inputs/SelectInput";
import { Tooltip } from "primereact/tooltip";
import { UrlInput } from "../../inputs/UrlInput";
import { IntentionInput } from "../../inputs/intentionInput/IntentionInput";
import { EntrenaInput } from "../../inputs/EntrenaInput";
import { CondicionInput } from "../../inputs/condicionInput/CondicionInput";
import { Help } from "../../Help";
import { ParametersInput } from "../../inputs/ParametersInput";
import { EntityInput } from "../../inputs/EntityInput";
import { useVoicebotStore } from "../../../store/voicebotStore";
import { useNodeTypesStore } from "../../../store/nodeTypesStore";
import { useGraphStore } from "../../../store/graphStore";
import { MensajeInput } from "../../inputs/MensajeInput";

const DynamicForm = ({
  node,
  handleElementChange,
  deleteNode,
  trackearForm,
}) => {
  const { elements, getElement } = useVoicebotStore((state) => state);

  const { getNodeType } = useNodeTypesStore((state) => state);

  const { drawMode, setDrawMode } = useGraphStore((state) => state);

  const [components, setComponents] = useState(null);
  const [flechas, setFlechas] = useState(null);
  const [tooltipLabel, setTooltipLabel] = useState(null);
  const _id = node.data.id;

  const _genericError = "Campo requerido";

  const getDataByType = (targetType) => {
    const result = getNodeType(targetType);
    if (result) {
      setComponents(result.form);
      setTooltipLabel(result.name);
      setFlechas(result.flechas);
    }
  };

  useEffect(() => {
    if (drawMode) setDrawMode(false);
    getDataByType(node.data.type);
  }, []);

  /** */
  const disabledException = (name, data) => {
    if (name === "timeTranscript" && data && data.finalResponse) {
      return true;
    }
    return false;
  };
  /** */

  const renderFormComponents = () => {
    return (
      components &&
      components.map((group) => {
        const { group: groupName, label, components: groupComponents } = group;

        return (
          <Panel
            key={groupName}
            header={label}
            style={{ width: "100%", marginBottom: "1rem" }}
          >
            {groupComponents.map((component) => {
              const { name, label, type, required, placeholder, help } =
                component;

              switch (type) {
                case "text":
                case "textnum":
                  return (
                    <div
                      key={name}
                      className="field"
                      style={{
                        display: disabledException(name, getElement(_id)?.data)
                          ? "none"
                          : "block",
                      }}
                    >
                      <div className="head-comp">
                        <label htmlFor={name}>{label}</label>
                        {help && help !== "" && <Help help={help} />}
                      </div>
                      <InputText
                        id={name}
                        name={name}
                        value={getElement(_id)?.data[name]}
                        onChange={(e) =>
                          handleElementChange(name, e.target.value, _id)
                        }
                        required={required}
                        placeholder={placeholder}
                        autoComplete="off"
                        type={type === "textnum" ? "number" : "text"}
                        className={classNames({
                          "p-invalid":
                            required && getElement(_id)?.data[name] === "",
                        })}
                      />
                      {required && getElement(_id)?.data[name] === "" && (
                        <small className="p-error block">{_genericError}</small>
                      )}
                    </div>
                  );

                case "texturl":
                  return (
                    <div key={name} className="field">
                      <div className="head-comp">
                        <label htmlFor={name}>{label}</label>
                        {help && help !== "" && <Help help={help} />}
                      </div>
                      <UrlInput
                        name={name}
                        required={required}
                        placeholder={placeholder}
                        handleElementChange={handleElementChange}
                        id={_id}
                        genericError={_genericError}
                      />
                    </div>
                  );

                case "intention":
                case "themes":
                  return (
                    <div key={name} className="field">
                      <div className="head-comp">
                        <label htmlFor={name}>{label}</label>
                        {help && help !== "" && <Help help={help} />}
                      </div>
                      <IntentionInput
                        name={name}
                        componentType={type}
                        required={required}
                        handleElementChange={handleElementChange}
                        id={_id}
                        genericError={_genericError}
                        flechasIni={flechas}
                        trackearForm={trackearForm}
                      />
                    </div>
                  );

                case "selectBody":
                case "selectMethod":
                  return (
                    <div key={name} className="field">
                      <div className="head-comp">
                        <label htmlFor={name}>{label}</label>
                        {help && help !== "" && <Help help={help} />}
                      </div>
                      <SelectInput
                        name={name}
                        componentType={type}
                        handleElementChange={handleElementChange}
                        required={required}
                        id={_id}
                        genericError={_genericError}
                      />
                    </div>
                  );

                case "multiselect":
                  return (
                    <div key={name} className="field">
                      <div className="head-comp">
                        <label htmlFor={name}>{label}</label>
                        {help && help !== "" && <Help help={help} />}
                      </div>
                      <MultiSelect
                        id={name}
                        name={name}
                        filter={true}
                        value={getElement(_id)?.data[name]}
                        options={null}
                        onChange={(e) =>
                          handleElementChange(name, e.value, _id)
                        }
                        required={required}
                        placeholder={placeholder}
                        className={classNames({
                          "p-invalid": required && !getElement(_id)?.data[name],
                        })}
                      />
                      {required && !getElement(_id)?.data[name] && (
                        <small className="p-error block">{_genericError}</small>
                      )}
                    </div>
                  );

                case "switch":
                  return (
                    <div
                      key={name}
                      className="field"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <InputSwitch
                        id={name}
                        name={name}
                        checked={getElement(_id)?.data[name]}
                        onChange={(e) =>
                          handleElementChange(name, e.value, _id)
                        }
                      />
                      <div className="head-comp">
                        <label
                          style={{ marginLeft: "6px", marginBottom: "0" }}
                          htmlFor={name}
                        >
                          {label}
                        </label>
                        {help && help !== "" && <Help help={help} />}
                      </div>
                    </div>
                  );

                case "entrenamiento":
                  return (
                    <div key={name} className="field">
                      <div className="head-comp">
                        <label htmlFor={name}>{label}</label>
                        {help && help !== "" && <Help help={help} />}
                      </div>
                      <EntrenaInput
                        id={_id}
                        handleElementChange={handleElementChange}
                        name={name}
                        required={required}
                        genericError={_genericError}
                      />
                    </div>
                  );

                case "mensaje":
                  return (
                    <div key={name} className="field">
                      <div className="head-comp">
                        <label htmlFor={name}>{label}</label>
                        {help && help !== "" && <Help help={help} />}
                      </div>
                      <MensajeInput
                        id={_id}
                        handleElementChange={handleElementChange}
                        name={name}
                        required={required}
                        genericError={_genericError}
                      />
                    </div>
                  );

                case "condicion":
                  return (
                    <div key={name} className="field">
                      <div className="head-comp">
                        <label htmlFor={name}>{label}</label>
                        {help && help !== "" && <Help help={help} />}
                      </div>
                      <CondicionInput
                        id={_id}
                        handleElementChange={handleElementChange}
                        name={name}
                        required={required}
                        genericError={_genericError}
                      />
                    </div>
                  );

                case "parameters":
                  return (
                    <div key={name} className="field">
                      <div className="head-comp">
                        <label htmlFor={name}>{label}</label>
                        {help && help !== "" && <Help help={help} />}
                      </div>
                      <ParametersInput
                        id={_id}
                        handleElementChange={handleElementChange}
                        name={name}
                        required={required}
                        genericError={_genericError}
                      />
                    </div>
                  );

                case "entities":
                  return (
                    <div key={name} className="field">
                      <div className="head-comp">
                        <label htmlFor={name}>{label}</label>
                        {help && help !== "" && <Help help={help} />}
                      </div>
                      <EntityInput
                        id={_id}
                        name={name}
                        required={required}
                        genericError={_genericError}
                        flechasIni={flechas}
                        trackearForm={trackearForm}
                      />
                    </div>
                  );

                case "quest":
                  return (
                    <div key={name} className="field">
                      <div className="head-comp">
                        <label htmlFor={name}>{label}</label>
                        {help && help !== "" && <Help help={help} />}
                      </div>
                      <div>
                        <PreguntaInput
                          id={_id}
                          handleElementChange={handleElementChange}
                          name={name}
                          required={required}
                        />
                        {required &&
                          elements.find(
                            (element) =>
                              element.data.id === _id &&
                              typeof element.data[name] === "object" &&
                              element.data[name] !== null &&
                              Object.keys(element.data[name]).length === 0
                          ) && (
                            <small className="p-error block">
                              {_genericError}
                            </small>
                          )}
                      </div>
                    </div>
                  );

                default:
                  return null;
              }
            })}
          </Panel>
        );
      })
    );
  };

  const reactiveName = () => {
    const _reactiveElement = elements.find(
      (elemento) => elemento.data.id === node.data.id || null
    );
    if (_reactiveElement) return _reactiveElement.data.name;
    return null;
  };

  return (
    <>
      <Button
        className="delete p-button-danger"
        icon="pi pi-trash"
        onClick={() => deleteNode(node)}
      />

      <div
        className="tipo"
        style={{
          backgroundColor: node.data.color,
        }}
      >
        <div
          className="icon"
          style={{
            backgroundImage: `url(${node.data.icon})`,
          }}
        ></div>

        <Tooltip target=".icon" position="left">
          {tooltipLabel}
        </Tooltip>

        {reactiveName()}
      </div>
      {renderFormComponents()}
    </>
  );
};

export default DynamicForm;
