import { create } from "zustand";

export const useGraphStore = create((set) => ({
  drawMode: false,
  setDrawMode: (drawMode) => set({ drawMode }),

  cleanDrawMode: () =>
    set(() => ({
      drawMode: false,
    })),
}));
