import React, { useRef } from "react";
import { Button, Toolbar } from "primereact";
import GraphComponent from "./graphComponent/GraphComponent";
import { Toast } from "primereact/toast";
import { useVoicebotStore } from "../store/voicebotStore";
import { useGraphStore } from "../store/graphStore";
import { Loader } from "./Loader";

export const VoicebotPanelContain = ({
  closePanel,
  saveVoicebot,
  voicebotLoading,
}) => {
  const toast = useRef(null);

  const { voicebot, voicebotRaw, estados, elements, nodeTrackers } =
    useVoicebotStore((state) => state);

  const { cleanDrawMode } = useGraphStore((state) => state);

  /** */
  const leftToolbarTemplate = () => {
    const _title =
      voicebot && voicebot.nombre ? voicebot.nombre : "Nuevo voicebot";
    const _idTitle = voicebot && voicebot.id ? voicebot.id : null;
    return (
      <div className="panel-title">
        {_title}
        {_idTitle && (
          <span style={{ fontSize: "12px", color: "#888", marginLeft: "8px" }}>
            Id: {_idTitle}
          </span>
        )}
      </div>
    );
  };

  /** */
  const buildAnswerQuestions = (_elements) => {
    _elements.forEach((node) => {
      if (node.group === "nodes") {
        node.data.answerQuestions = null;
      }
    });

    const nodesById = elements
      .filter((element) => element.group === "nodes")
      .reduce((map, node) => {
        map[node.data.id] = node;
        return map;
      }, {});

    Object.keys(nodesById).forEach((nodeId) => {
      const node = nodesById[nodeId];
      const answerQuestions = _elements
        .filter(
          (element) =>
            element.group === "edges" && element.data.source === nodeId
        )
        .map((edge) => ({
          option: edge.data.edgeOption,
          question: edge.data.target,
        }));

      node.data.answerQuestions = answerQuestions;
    });

    return _elements;
  };

  const buildNextQuestions = (_elements) => {
    _elements.forEach((node) => {
      if (node.group === "nodes") {
        node.data.defaultNextQuestion = null;
        node.data.hangUpNextQuestion = null;
      }
    });
    _elements.forEach((element) => {
      if (element.group === "edges") {
        const { edgeOption, source, target } = element.data;
        _elements.forEach((node) => {
          if (node.group === "nodes" && node.data.id === source) {
            if (edgeOption === "default") {
              node.data.defaultNextQuestion = target;
            } else if (edgeOption === "hang_up") {
              node.data.hangUpNextQuestion = target;
            }
          }
        });
      }
    });
    return _elements;
  };

  const fixEstados = (_estados) => {
    return _estados.map((estado) => {
      if (estado.questionId) {
        estado.estado = `${estado.question} - ${estado.flechita}`;
      }
      return estado;
    });
  };

  const armarVoicebot = () => {
    const _voicebot = { ...voicebotRaw, ...voicebot };
    _voicebot.estados = fixEstados(estados);
    _voicebot.elements = buildAnswerQuestions(elements);
    _voicebot.elements = buildNextQuestions(elements);

    return _voicebot;
  };

  /** */
  const validateGeneral = () => {
    const validarEstados = () => {
      return !estados.some((estado) => estado.nombre === "");
    };
    if (validarEstados() === false) {
      return false;
    }
    if (voicebot.nombre === "") {
      return false;
    }
    if (elements.length === 0) {
      return false;
    }
    return nodeTrackers.every((nodeTracker) => {
      return nodeTracker.validForm !== false;
    });
  };

  const seRepiteNombre = () => {
    const nodes = elements.filter((element) => element.group === "nodes");
    const nombres = nodes.map((node) => node.data.name);
    const conjuntoNombres = new Set(nombres);
    return conjuntoNombres.size !== nombres.length;
  };

  const arbolCortado = () => {
    const nodes = elements.filter((element) => element.group === "nodes");
    const edges = elements.filter((element) => element.group === "edges");
    const targetIds = edges.map((edge) => edge.data.target);

    return nodes.some((node) => {
      if (node.data.id === voicebot.firstQuestion) {
        return false;
      }
      return !targetIds.includes(node.data.id);
    });
  };

  const handleSave = () => {
    if (arbolCortado()) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "El arbol está fragmentado",
        life: 3000,
      });
    } else if (seRepiteNombre()) {
      toast.current.show({
        severity: "error",
        summary: "Eliminar nodo",
        detail: "Los nodos deben tener nombres únicos",
        life: 3000,
      });
    } else {
      if (validateGeneral()) {
        // console.log(armarVoicebot());
        saveVoicebot(armarVoicebot());
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Faltan campos requeridos",
          life: 3000,
        });
      }
    }
  };

  const handleClose = () => {
    cleanDrawMode();
    closePanel();
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        <div className="actions">
          <Button
            icon="pi pi-check"
            label="Guardar"
            className="p-button-success"
            onClick={() => handleSave()}
          />
          <Button
            icon="pi pi-times"
            label="Cancelar"
            onClick={() => handleClose()}
          />
        </div>
      </>
    );
  };

  return (
    <div className="slide-panel">
      <Toast ref={toast} />
      {voicebotLoading ? (
        <Loader />
      ) : (
        <>
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>

          <div className="voicebot">
            <GraphComponent />
          </div>
        </>
      )}
    </div>
  );
};
