import React from "react";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useVoicebotStore } from "../../store/voicebotStore";

export const UrlInput = ({
  name,
  required,
  placeholder,
  handleElementChange,
  id,
  genericError,
}) => {
  const { getElement } = useVoicebotStore((state) => state);
  let _error;

  const isValidUrl = (url) => {
    if (url === "") {
      _error = genericError;
      return false;
    } else {
      _error = "Formato incorrecto";
      const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
      return urlPattern.test(url);
    }
  };

  return (
    <>
      <InputText
        id={name}
        name={name}
        value={getElement(id)?.data[name]}
        onChange={(e) => handleElementChange(name, e.target.value, id)}
        required={required}
        placeholder={placeholder}
        autoComplete="off"
        type="text"
        className={classNames({
          "p-invalid": required && !isValidUrl(getElement(id)?.data[name]),
        })}
      />
      {required && !isValidUrl(getElement(id)?.data[name]) && (
        <small className="p-error block">{_error}</small>
      )}
    </>
  );
};
