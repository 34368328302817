import React from "react";

export const Operators = ({ handleOperatorClick, opTypes }) => {
  const operatorsData = [
    { symbol: "(", type: "par" },
    { symbol: ")", type: "par" },
    { symbol: "==", type: "comp" },
    { symbol: "!=", type: "comp" },
    { symbol: "<", type: "comp" },
    { symbol: ">", type: "comp" },
    { symbol: "<=", type: "comp" },
    { symbol: ">=", type: "comp" },
    { symbol: "&&", type: "logic" },
    { symbol: "||", type: "logic" },
    { symbol: "!", type: "logic" },
    { symbol: "+", type: "arit" },
    { symbol: "-", type: "arit" },
    { symbol: "*", type: "arit" },
    { symbol: "/", type: "arit" },
    { symbol: "%", type: "arit" },
    { symbol: "V", type: "bool" },
    { symbol: "F", type: "bool" },
  ];

  return (
    <div className="operadores">
      {operatorsData.map((operator, index) => (
        <OperatorButton
          key={index}
          data={operator}
          onClick={handleOperatorClick}
          opTypes={opTypes}
        />
      ))}
    </div>
  );
};

const OperatorButton = ({ data, onClick, opTypes }) => {
  const getColor = (type) => {
    const foundType = opTypes.find((op) => op.type === type);
    return foundType ? foundType.color : "#DFDFDF";
  };

  return (
    <button
      className="op"
      style={{ backgroundColor: getColor(data.type) }}
      onClick={() => onClick(data.symbol, data.type)}
    >
      {data.symbol}
    </button>
  );
};

export const opTypes = [
  {
    type: "par",
    color: "#C8E6C9",
  },
  {
    type: "comp",
    color: "#FEEDAF",
  },
  {
    type: "logic",
    color: "#FFCDD2",
  },
  {
    type: "arit",
    color: "#ECCFFF",
  },
  {
    type: "bool",
    color: "#d7cea9",
  },
  {
    type: "text",
    color: "#DFDFDF",
  },
  {
    type: "num",
    color: "#bdc0f4",
  },
  {
    type: "var",
    color: "#FFD8B2",
  },
  {
    type: "nod",
    color: "#B3E5FC",
  },
];
