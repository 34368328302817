import { useQuery } from "@tanstack/react-query";
import { viewTypes } from "../common/types/views.type";
import intentionService from "../services/intention.service";

const fetchIntention = async (filter) => {
  const { vnVoicebot: viewName, vfVerVoiceBot: viewFunction } = viewTypes;
  const userName = localStorage.getItem("userName");

  const response = await intentionService.ObtenerIntenciones(
    filter,
    userName,
    viewName,
    viewFunction
  );
  return response.data.intentions;
};

export function useFetchIntention(filter) {
  return useQuery({
    queryKey: ["intentions"],
    queryFn: () => fetchIntention(filter),
    retry: false,
    refetchOnWindowFocus: false,
  });
}
