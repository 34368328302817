import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { useVoicebotStore } from "../../store/voicebotStore";
import { useFetchBody } from "../../hooks/useBody";

const SelectInput = ({
  name,
  componentType,
  handleElementChange,
  required,
  id,
  genericError,
}) => {
  const { getElement } = useVoicebotStore((state) => state);

  const [options, setOptions] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (name === "method")
      setOptions([
        {
          nombre: "GET",
          id: "GET",
        },
        {
          nombre: "POST",
          id: "POST",
        },
      ]);
  }, []);

  /** */
  const _filter = {
    rows: 8000,
  };

  const filtrarBodies = (opt) => {
    return opt.map((elemento) => ({
      id: elemento.id,
      nombre: elemento.nombre,
      values: elemento.atributos,
    }));
  };

  const fetchBody = useFetchBody(_filter);
  useEffect(() => {
    if (
      fetchBody.data &&
      (name === "bodyWSRequest" || name === "bodyWSResponse")
    ) {
      setOptions(filtrarBodies(fetchBody.data));
    }
  }, [fetchBody.data]);

  /*** */
  const getValue = (value) => {
    switch (componentType) {
      case "selectBody":
        if (value) {
          return value.id;
        }
        break;

      case "selectMethod":
      default:
        if (value) {
          return value;
        }
        break;
    }
  };

  /*** */
  const handleChange = (name, value, id) => {
    switch (componentType) {
      case "selectBody":
        const _value = options.find((element) => element.id === value);
        handleElementChange(name, _value, id);
        break;

      case "selectMethod":
      default:
        handleElementChange(name, value, id);
        break;
    }
  };

  return (
    <>
      <Dropdown
        id={name}
        name={name}
        value={getValue(getElement(id)?.data[name])}
        options={options}
        optionLabel="nombre"
        optionValue="id"
        onChange={(e) => handleChange(name, e.value, id)}
        required={required}
        placeholder={!loading ? "Selecciona" : "Cargando..."}
        className={classNames({
          "p-invalid": required && !getElement(id)?.data[name],
        })}
      />
      {required && !getElement(id)?.data[name] && (
        <small className="p-error block">{genericError}</small>
      )}
    </>
  );
};

export default SelectInput;
