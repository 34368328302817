import React from "react";
import { Dropdown } from "primereact/dropdown";
import { Entities } from "./Entities";

export const SingleComponent = ({
  singleValue,
  entidades,
  setEntidades,
  setSingleValue,
  options,
  handleSimpleIntention,
  handleSimpleEntidades,
  loading,
  genericError,
}) => {
  const handleChangeIntention = (value) => {
    setSingleValue(value);
    handleSimpleIntention(value);
  };

  return (
    <>
      <Dropdown
        value={singleValue}
        options={options}
        optionLabel="label"
        onChange={(e) => handleChangeIntention(e.value)}
        placeholder={!loading ? "Selecciona" : "Cargando..."}
      />
      {entidades && (
        <Entities
          entidades={entidades}
          setEntidades={setEntidades}
          handleSimpleEntidades={handleSimpleEntidades}
          genericError={genericError}
        />
      )}
    </>
  );
};
