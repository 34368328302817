import http from "../common/components/http-common";

class IntentionService {
  ObtenerIntenciones(
    data,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
      params: {
        with_entities: true,
        nombre: data.nombre,
        userId: data.userId,
        size: data.rows,
        page: data.page,
        sortBy: data.sortField,
        direction: data.direction,
      },
    };

    return http.get<any>("/v1/intention", config);
  }
}

export default new IntentionService();
