import { useQuery } from "@tanstack/react-query";
import { viewTypes } from "../common/types/views.type";
import voicebotService from "../services/voicebot.service";

const { vnVoicebot: viewName, vfVerVoiceBot: viewFunction } = viewTypes;
const userName = localStorage.getItem("userName");

// Fetch voicebot
const fetchVoicebot = async (filter) => {
  const response = await voicebotService.ObtenerVoicebots(
    filter,
    userName,
    viewName,
    viewFunction
  );
  return response.data;
};

export function useFetchVoicebot(filter) {
  return useQuery({
    queryKey: ["voicebot", filter],
    queryFn: () => fetchVoicebot(filter),
  });
}

// Fetch voicebot by id
const fetchVoicebotById = async (id) => {
  const response = await voicebotService.getVoicebot(
    id,
    userName,
    viewName,
    viewFunction
  );
  return response.data;
};

export function useFetchVoicebotById(id) {
  return useQuery({
    queryKey: ["vbot", id],
    queryFn: () => fetchVoicebotById(id),
    enabled: !!id,
    retry: false,
    refetchOnWindowFocus: false,
  });
}
/** */
