import React, { useState } from "react";

export const Help = ({ help }) => {
  const [show, setShow] = useState(false);

  return (
    <div className="help">
      <button className="boton-ayuda" onClick={() => setShow(!show)}>
        <i className="pi pi-question-circle"></i>
      </button>
      {show && (
        <div className="balloon">
          <button className="close" onClick={() => setShow(!show)}>
            <i className="pi pi-times"></i>
          </button>
          {help}
        </div>
      )}
    </div>
  );
};
