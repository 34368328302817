import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { genId } from "../../utils/idGenerator";
import { useVoicebotStore } from "../../store/voicebotStore";

export const ParametersInput = ({
  id,
  handleElementChange,
  name,
  required,
  genericError,
}) => {
  const _bgColor = "#FFD8B2";
  const _color = "rgb(128, 91, 54)";
  /** */
  const [variable, setVariable] = useState("");
  const [items, setItems] = useState([]);

  const { elements, getElement } = useVoicebotStore((state) => state);

  useEffect(() => {
    getElement(id)?.data[name] && setItems(getElement(id)?.data[name].formats);
  }, []);

  const handleEvent = (_items) => {
    setItems(_items);
    const parseString = (items) => {
      return items
        .map((item) => {
          return item.text;
        })
        .join(",");
    };

    if (_items.length > 0) {
      const _params = {
        formats: _items,
        string: parseString(_items),
      };
      handleElementChange(name, _params, id);
    } else {
      handleElementChange(name, "", id);
    }
  };

  const handleKeyDown = (event, value) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleInsertItem(value);
    }
  };

  /** */
  const handleInsertItem = (text, id: string | null = null) => {
    const newItem = {
      id: id ? id : genId(),
      text,
    };
    handleEvent([...items, newItem]);
    setVariable("");
  };

  const eliminarItem = (_id) => {
    const newItems = items.filter((item) => item.id !== _id);
    handleEvent(newItems);
  };

  const handleDragStart = (e, id) => {
    e.dataTransfer.setData("id", id);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    const id = e.dataTransfer.getData("id");
    const nuevoOrden = items.filter((item) => item.id !== id);
    const indice = Array.from(e.target.parentNode.children).indexOf(e.target);
    nuevoOrden.splice(
      indice,
      0,
      items.find((item) => item.id === id)
    );
    setItems(nuevoOrden);
  };

  return (
    <>
      <div
        className="parameters"
        style={{
          borderColor:
            required && getElement(id) && getElement(id).data[name] === ""
              ? "#f44336"
              : "#ced4da",
        }}
      >
        <div
          className="texto-drag"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          {items.map((item) => (
            <button
              key={item.id}
              draggable="true"
              className="item"
              onDragStart={(e) => handleDragStart(e, item.id)}
              onClick={() => eliminarItem(item.id)}
              style={{
                backgroundColor: _bgColor,
                color: _color,
              }}
            >
              {item.text}
            </button>
          ))}
        </div>

        <div className="form">
          <div className="selectores">
            <div className="grupo">
              <div
                className="circulo"
                style={{
                  marginLeft: 0,
                  marginRight: "4px",
                  backgroundColor: _bgColor,
                }}
              ></div>

              <InputText
                value={variable}
                onChange={(e) => setVariable(e.target.value)}
                placeholder="Variable"
                autoComplete="off"
                onKeyDown={(e) => handleKeyDown(e, variable)}
              />
              <Button
                className="plus"
                icon="pi pi-plus"
                onClick={() => handleInsertItem(variable)}
                disabled={variable === ""}
              />
            </div>
          </div>
        </div>
      </div>
      {required && getElement(id) && getElement(id).data[name] === "" && (
        <small className="p-error block">{genericError}</small>
      )}
    </>
  );
};
