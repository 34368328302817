import http from "../common/components/http-common";

export const copySurvey = (
  userName: string,
  viewName: string,
  viewFunction: string,
  id: string,
  idUser: string,
  surveyName: string
) => {
  let config = {
    headers: {
      userName: userName,
      viewName: viewName,
      viewFunction: viewFunction,
    },
  };

  return http.post<any>(
    `/surveys/${id}/idUser/${idUser}/surveyName/${surveyName}/copy`,
    config
  );
};

export const asignarSurvey = (
  userName: string,
  viewName: string,
  viewFunction: string,
  id: string,
  idUser: string
) => {
  let config = {
    headers: {
      userName: userName,
      viewName: viewName,
      viewFunction: viewFunction,
    },
  };

  return http.post<any>(`/surveys/${id}/idUser/${idUser}/assign`, config);
};
