import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { Panel } from "primereact/panel";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { useVoicebotStore } from "../../../store/voicebotStore";
import { useNodeTypesStore } from "../../../store/nodeTypesStore";
import { useGraphStore } from "../../../store/graphStore";

const DynamicEdge = ({
  edge,
  deleteEdges,
  nodeSource,
  setNodeSource,
  getEdgeOption,
  formatEdgeOption,
}) => {
  const {
    elements,
    estados,
    updateElementProp,
    updateStateEdgeProp,
    getNodeTracker,
  } = useVoicebotStore((state) => state);

  const { getNodeType } = useNodeTypesStore((state) => state);
  const { drawMode, setDrawMode } = useGraphStore((state) => state);

  const _id = edge.data.id;

  const [nodeTarget, setNodeTarget] = useState(null);

  const obtenerNodos = (source, target) => {
    const nodoSrc = elements.find((node) => node.data.id === source);
    const nodoTgt = elements.find((node) => node.data.id === target);

    const nodeTracker = getNodeTracker(nodoSrc.data.id);

    if (nodoSrc) {
      const _nodeType = getNodeType(nodoSrc.data.type);

      setNodeSource({
        nodeType: _nodeType,
        nodo: nodoSrc,
        nodeTracker: nodeTracker,
      });
    }

    if (nodoTgt) {
      setNodeTarget({
        nodeType: getNodeType(nodoTgt.data.type),
        nodo: nodoTgt,
      });
    }
  };

  const handleCombo = (value, _id) => {
    const _edgesOptions = {
      anterior: edge.data.edgeOption,
      actual: value,
    };
    updateElementProp(_id, "edgeOption", value);
    updateStateEdgeProp(_id, "flechita", value);
    updateStateEdgeProp(_id, "nombre", formatEdgeOption(value));
    getEdgeOption(nodeSource.nodo.data.id, _edgesOptions);
  };

  useEffect(() => {
    obtenerNodos(edge.data.source, edge.data.target);
    if (drawMode) setDrawMode(false);
  }, []);

  return (
    <>
      <Button
        className="delete p-button-danger"
        icon="pi pi-trash"
        onClick={() => deleteEdges([edge])}
      />
      <Panel header="Relación" style={{ width: "100%" }}>
        <div className="field">
          <label htmlFor="nombre">Nombre</label>
          <InputText
            name="name"
            value={estados.find((estado) => estado.flechitaId === _id)?.nombre}
            onChange={(e) => updateStateEdgeProp(_id, "nombre", e.target.value)}
            autoComplete="off"
            className={classNames({
              "p-invalid":
                estados.find((estado) => estado.flechitaId === _id)?.nombre ===
                "",
            })}
          />
          {estados.find((estado) => estado.flechitaId === _id)?.nombre ===
            "" && <small className="p-error block">{"Campo requerido"}</small>}
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            flexDirection: "column",
          }}
        >
          {nodeSource && (
            <>
              <Nodo node={nodeSource} />
              <i
                style={{ margin: "8px 8px 0 8px" }}
                className="pi pi-arrow-down"
              ></i>
            </>
          )}

          {nodeSource && (
            <Dropdown
              style={{ marginTop: "6px" }}
              name="tipo"
              value={
                elements.find((element) => element.data.id === _id)?.data
                  .edgeOption
              }
              options={nodeSource.nodeTracker.flechas}
              optionLabel="name"
              onChange={(e) => handleCombo(e.value, _id)}
              placeholder="Selecciona un tipo"
            />
          )}

          {nodeTarget && (
            <>
              <i
                style={{ margin: "8px 8px 0 8px" }}
                className="pi pi-arrow-down"
              ></i>
              <Nodo node={nodeTarget} />
            </>
          )}
        </div>
      </Panel>
    </>
  );
};

export default DynamicEdge;

const Nodo = ({ node }) => {
  return (
    <div
      className="node"
      style={{
        backgroundColor: node.nodeType.color,
        color: "white",
        display: "flex",
        alignItems: "center",
        padding: "2px 12px 2px 8px",
        borderRadius: "16px",
        position: "relative",
        top: "5px",
      }}
    >
      <div
        className="icon"
        style={{
          backgroundImage: `url(${node.nodeType.icon})`,
          backgroundSize: "cover",
          height: "28px",
          width: "28px",
        }}
      ></div>
      <div className="label">{node.nodo.data.name}</div>
    </div>
  );
};
