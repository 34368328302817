import http from "../common/components/http-common";

class EntityService {
  ObtenerEntities(
    data,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
      params: {
        display_name: data.display_name,
        context: data.context,
        userId: data.userId,
        size: data.rows,
        page: data.page,
        sortBy: data.sortField,
        direction: data.direction,
      },
    };

    return http.get<any>("/v1/entity", config);
  }
}

export default new EntityService();
