import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Operators, opTypes } from "./Operators";
import { NodeSelect } from "./NodeSelect";
import { genId } from "../../../utils/idGenerator";
import { useVoicebotStore } from "../../../store/voicebotStore";

export const CondicionInput = ({
  id,
  handleElementChange,
  name,
  required,
  genericError,
}) => {
  const { updateElementProp, getElement } = useVoicebotStore((state) => state);
  /** */
  const [variable, setVariable] = useState("");
  const [numero, setNumero] = useState("");
  const [texto, setTexto] = useState("");
  const [nodes, setNodes] = useState([]);
  const [items, setItems] = useState([]);

  useEffect(() => {
    getElement(id)?.data[name] && setItems(getElement(id)?.data[name].formats);
  }, []);

  const handleEvent = (_items, updatedNodes = null) => {
    setItems(_items);

    const parseString = (_items) => {
      return _items
        .map((item) => {
          if (item.type === "nod") {
            return item.text.replace(/(\w+)-(\d+)==/g, "{nodo.$1-$2}==");
          } else if (item.type === "var") {
            return `{var.${item.text}}`;
          } else if (item.type === "bool") {
            return item.text === "V" ? "true" : "false";
          } else {
            return item.text;
          }
        })
        .join("");
    };

    if (_items.length > 0) {
      const _condicion = {
        formats: _items,
        string: parseString(_items),
        nodes: updatedNodes ? updatedNodes : nodes,
      };
      handleElementChange(name, _condicion, id);
    } else {
      handleElementChange(name, "", id);
    }
  };

  const handleKeyDown = (event, value, type) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleInsertItem(value, type);
    }
  };

  const handleOperatorClick = (content, type) => {
    handleInsertItem(content, type);
  };

  const [nodeItem, setNodeItem] = useState(null);
  const [nodeChildItem, setNodeChildItem] = useState(null);

  const handleSelectNode = (nodo, flecha) => {
    const _nodo = `${nodo.name}=='${flecha}'`;
    const _id = genId();

    const _nodeObj = {
      idNode: nodo.id,
      idItem: _id,
    };

    setNodes((nodes) => {
      const newNodes = [...nodes, _nodeObj];
      handleInsertItem(_nodo, "nod", _id, newNodes);
      return newNodes;
    });

    setNodeItem(null);
    setNodeChildItem(null);
  };

  /** */
  const handleInsertItem = (text, type, id = null, updatedNodes = null) => {
    const newItem = {
      id: id ? id : genId(),
      text,
      type,
    };

    handleEvent([...items, newItem], updatedNodes);

    setVariable("");
    setNumero("");
    setTexto("");
  };

  const eliminarItem = (_id) => {
    const newItems = items.filter((item) => item.id !== _id);
    const newNodes = nodes.filter((node) => node.idItem !== _id);

    handleEvent(newItems, newNodes);

    if (newNodes.length !== nodes.length) {
      setNodes(newNodes);
      const _condicion = getElement(id)?.data[name];
      _condicion.nodes = newNodes;
      updateElementProp(id, name, _condicion);
    }
  };

  const handleDragStart = (e, id) => {
    e.dataTransfer.setData("id", id);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    const id = e.dataTransfer.getData("id");
    const nuevoOrden = items.filter((item) => item.id !== id);
    const indice = Array.from(e.target.parentNode.children).indexOf(e.target);
    nuevoOrden.splice(
      indice,
      0,
      items.find((item) => item.id === id)
    );
    setItems(nuevoOrden);
  };

  return (
    <>
      <div
        className="condicion"
        style={{
          borderColor:
            required && getElement(id) && getElement(id).data[name] === ""
              ? "#f44336"
              : "#ced4da",
        }}
      >
        <div
          className="texto-drag"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          {items?.map((item) => (
            <button
              key={item.id}
              draggable="true"
              className="item"
              onDragStart={(e) => handleDragStart(e, item.id)}
              onClick={() => eliminarItem(item.id)}
              style={{
                backgroundColor: opTypes.find((op) => op.type === item.type)
                  .color,
              }}
            >
              {item.text}
            </button>
          ))}
        </div>

        <div className="form">
          <div className="selectores">
            <div className="grupo">
              <div
                className="circulo"
                style={{
                  marginLeft: 0,
                  marginRight: "4px",
                  backgroundColor: opTypes.find((op) => op.type === "text")
                    .color,
                }}
              ></div>
              <InputText
                value={texto}
                onChange={(e) => setTexto(e.target.value)}
                placeholder="Texto"
                autoComplete="off"
                onKeyDown={(e) => handleKeyDown(e, `'${texto}'`, "text")}
              />
              <Button
                className="plus"
                icon="pi pi-plus"
                onClick={() => handleInsertItem(`'${texto}'`, "text")}
                disabled={texto === ""}
              />
            </div>

            <div className="grupo">
              <div
                className="circulo"
                style={{
                  marginLeft: 0,
                  marginRight: "4px",
                  backgroundColor: opTypes.find((op) => op.type === "num")
                    .color,
                }}
              ></div>
              <InputText
                value={numero}
                onChange={(e) => setNumero(e.target.value)}
                placeholder="Numero"
                autoComplete="off"
                type="number"
                onKeyDown={(e) => handleKeyDown(e, numero, "num")}
              />
              <Button
                className="plus"
                icon="pi pi-plus"
                onClick={() => handleInsertItem(numero, "num")}
                disabled={numero === ""}
              />
            </div>

            <div className="grupo">
              <div
                className="circulo"
                style={{
                  marginLeft: 0,
                  marginRight: "4px",
                  backgroundColor: opTypes.find((op) => op.type === "var")
                    .color,
                }}
              ></div>

              <InputText
                value={variable}
                onChange={(e) => setVariable(e.target.value)}
                placeholder="Variable"
                autoComplete="off"
                onKeyDown={(e) => handleKeyDown(e, variable, "var")}
              />
              <Button
                className="plus"
                icon="pi pi-plus"
                onClick={() => handleInsertItem(variable, "var")}
                disabled={variable === ""}
              />
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "fit-content",
              }}
            >
              <div
                className="circulo"
                style={{
                  marginLeft: 0,
                  marginRight: "4px",
                  backgroundColor: opTypes.find((op) => op.type === "nod")
                    .color,
                }}
              ></div>
              <NodeSelect
                handleSelectNode={handleSelectNode}
                nodeItem={nodeItem}
                setNodeItem={setNodeItem}
                nodeChildItem={nodeChildItem}
                setNodeChildItem={setNodeChildItem}
              />
            </div>
          </div>

          <Operators
            handleOperatorClick={handleOperatorClick}
            opTypes={opTypes}
          />
        </div>
      </div>
      {required && getElement(id) && getElement(id).data[name] === "" && (
        <small className="p-error block">{genericError}</small>
      )}
    </>
  );
};
