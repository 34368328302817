import React, { useEffect, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { genId } from "../../../utils/idGenerator";
import { MultiComponent } from "./MultiComponent";
import { SingleComponent } from "./SingleComponent";
import { useFetchIntention } from "../../../hooks/useIntention";
import { useVoicebotStore } from "../../../store/voicebotStore";

export const IntentionInput = ({
  name,
  componentType,
  required,
  handleElementChange,
  id,
  genericError,
  flechasIni,
  trackearForm,
}) => {
  const {
    elements,
    getElement,
    setElements,
    estados,
    setEstados,
    getNodeTracker,
    updateNodeTracker,
  } = useVoicebotStore((state) => state);

  const filtrarOptions = (opt) => {
    return opt.map((elemento) => ({
      value: elemento.id,
      label: elemento.nombre,
      entities: elemento.entities,
    }));
  };
  const _filterIntention = {
    rows: 8000,
  };
  const fetchIntention = useFetchIntention(_filterIntention);
  useEffect(() => {
    if (fetchIntention.data) {
      setOptions(filtrarOptions(fetchIntention.data));
    }
    setLoading(fetchIntention.isLoading);
  }, [fetchIntention.data]);
  /** */

  const [loading, setLoading] = useState(null);
  const [options, setOptions] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);

  const setInitialTab = () => {
    const _intention = elements.find((elemento) => elemento.data.id === id)
      .data[name];

    if (_intention === "" || _intention.multiple === null) {
      setActiveIndex(0);
    } else {
      setActiveIndex(1);
    }
  };

  const resetFlechas = () => {
    let handler = manejarFlechas([]);
    handler._intention = "";

    changeState(handler);
  };

  const handleTabChange = (index) => {
    setActiveIndex(index);

    setEntidades(null);
    setSingleValue(null);

    resetFlechas();
  };

  useEffect(() => {
    componentType === "intention" && setInitialTab();
    preloadIntention();
  }, []);
  /** */

  const changeState = (_handler) => {
    if (
      _handler._intention.multiple &&
      _handler._intention.multiple.length === 0
    ) {
      _handler._intention = "";
    }

    const filterElements = (_handler) => {
      const deletedIds = new Set(
        _handler._deletedEdges.map((edge) => edge.data.id)
      );

      return elements.filter((element) => {
        if (deletedIds.has(element.data.id)) {
          return false;
        }
        if (element.data.id === id) {
          element.data[name] = _handler._intention;
        }

        const _nodeTracker = {
          ...getNodeTracker(id),
          ..._handler._nodeTracker,
        };
        updateNodeTracker(id, _nodeTracker);

        return true;
      });
    };

    const filterEstados = (deletedEdges) => {
      const deletedIds = new Set(deletedEdges.map((edge) => edge.data.id));
      return estados.filter((estado) => !deletedIds.has(estado.flechitaId));
    };

    setElements(filterElements(_handler));
    setEstados(filterEstados(_handler._deletedEdges));
    trackearForm(id);
  };
  /** */

  const manejarFlechas = (_intentions) => {
    const combineFlechas = (f1, f2) => {
      const flechasMap = new Map();
      [...f1, ...f2].forEach((flecha) => {
        flechasMap.set(flecha.value, flecha);
      });
      return Array.from(flechasMap.values());
    };

    const _flechasInt = _intentions.map(({ label }) => ({
      value: label,
      name: label,
    }));
    const _flechas = combineFlechas(flechasIni, _flechasInt);

    const flechasConDisabled = _flechas.map((flecha) => ({
      ...flecha,
      disabled: false,
    }));
    const flechasDisponibles = flechasConDisabled.filter(
      (flecha) => !flecha.disabled
    ).length;

    estados.forEach((estado) => {
      if (id === estado.questionId) {
        flechasConDisabled.forEach((flecha) => {
          if (flecha.value === estado.flechita) {
            flecha.disabled = true;
          }
        });
      }
    });

    const _nodeTracker = {
      flechas: flechasConDisabled,
      disponibles: flechasDisponibles,
    };

    /* edges */
    const filtrarElements = (elements, id, flechas) => {
      const nombresFlechas = flechas.map((flecha) => flecha.name);
      const filteredElements = elements.filter((element) => {
        if (element.group === "edges" && element.data.source === id) {
          return !nombresFlechas.includes(element.data.edgeOption);
        }
        return false;
      });
      return filteredElements;
    };

    const _deletedEdges = filtrarElements(elements, id, _nodeTracker.flechas);

    return {
      _nodeTracker,
      _deletedEdges,
      _intention: null,
    };
  };

  const handleMultiple = (value) => {
    const getObjects = () => {
      return value
        .map((val) => {
          const option = options.find((opt) => opt.value === val);
          return option ? { value: val, label: option.label } : null;
        })
        .filter((item) => item !== null);
    };

    let _intention;
    const _value = getObjects();

    const parsearValue = (_value) => {
      return Array.isArray(_value) && _value.length > 0 ? _value : "";
    };

    if (componentType !== "intention") {
      _intention = parsearValue(_value);
    } else {
      _intention = {
        simple: null,
        multiple: _value,
      };
    }
    /** */
    let handler = manejarFlechas(_value);
    handler._intention = _intention;

    changeState(handler);
  };

  const [singleValue, setSingleValue] = useState(null);
  const [entidades, setEntidades] = useState(null);

  const preloadIntention = () => {
    const _value = elements.find((element) => element.data.id === id)?.data[
      name
    ].simple;

    if (_value) {
      setEntidades(_value.entities);
      setSingleValue(_value.value);
    }
  };

  /** */
  const handleSimpleIntention = (value) => {
    const _value = options.find((obj) => obj.value === value);

    const _intention = {
      simple: _value,
      multiple: null,
    };

    const _entidades = _value.entities.map((elemento) => ({
      ...elemento,
      locution: null,
      key: elemento.key || genId(),
    }));

    setEntidades(_entidades);
    /** */
    let handler = manejarFlechas([_value]);
    handler._intention = _intention;

    changeState(handler);
  };

  /** */
  const handleSimpleEntidades = (_entidades) => {
    const _intention = elements.find((elemento) => elemento.data.id === id)
      .data[name];

    _intention.simple.entities = _entidades;
    handleElementChange(name, _intention, id);
  };

  /** */
  const shouldShowError = (id, name) => {
    const element = getElement(id);

    if (!element) return false;

    const data = element.data[name];
    const isRequired = required && data === "";
    if (!data.simple || !data.simple.entities) return isRequired;
    const hasEntityWithNullKey = data.simple.entities.some(
      (entity) => entity.key === null
    );
    const hasNullLocution = data.simple.entities.some(
      (entity) => entity.locution === null
    );
    return isRequired || hasEntityWithNullKey || hasNullLocution;
  };

  return (
    <>
      {componentType !== "intention" ? (
        <MultiComponent
          id={id}
          options={options}
          name={name}
          handleMultiple={handleMultiple}
          loading={loading}
          width="530px"
          required={required}
          componentType={componentType}
        />
      ) : (
        <div
          style={{
            border: "1px solid",
            borderColor:
              required && shouldShowError(id, name) ? "#f44336" : "#ced4da",
            borderRadius: "3px",
            padding: "4px",
          }}
        >
          <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => handleTabChange(e.index)}
          >
            <TabPanel header="Simple">
              <SingleComponent
                singleValue={singleValue && singleValue}
                setSingleValue={setSingleValue}
                options={options}
                handleSimpleIntention={handleSimpleIntention}
                handleSimpleEntidades={handleSimpleEntidades}
                loading={loading}
                entidades={entidades}
                setEntidades={setEntidades}
                genericError={genericError}
              />
            </TabPanel>
            <TabPanel header="Múltiple">
              <MultiComponent
                id={id}
                options={options}
                name={name}
                handleMultiple={handleMultiple}
                loading={loading}
                width="482px"
                required={required}
                componentType={componentType}
              />
            </TabPanel>
          </TabView>
        </div>
      )}
      {required && shouldShowError(id, name) && (
        <small className="p-error block">{genericError}</small>
      )}
    </>
  );
};
