import { useQuery } from "@tanstack/react-query";
import { getAllTypes } from "../services/types.service";

const fetchNodeTypes = async () => {
  const response = await getAllTypes();
  return response.data;
};

export function useFetchNodeTypes() {
  return useQuery({
    queryKey: ["nodeTypes"],
    queryFn: fetchNodeTypes,
    retry: false,
    refetchOnWindowFocus: false,
  });
}
