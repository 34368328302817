import React, { useEffect, useRef, useState } from "react";
import { Paginator, Toast } from "primereact";
import { viewTypes } from "../common/types/views.type";
import { ShowView } from "../common/components/ShowView";
import { Panel } from "./_slidePanel/Panel";
import { VoicebotPanelContain } from "./VoicebotPanelContain";
import voicebotService from "../services/voicebot.service";
import { VoicebotsTable } from "./VoicebotsTable";
import { downloadTemplate } from "../services/graph.service";
import { useFetchNodeTypes } from "../hooks/useNodeTypes";
import { useFetchUser } from "../hooks/useUser";
import { useFetchVoicebot, useFetchVoicebotById } from "../hooks/useVoicebot";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNodeTypesStore } from "../store/nodeTypesStore";
import { useVoicebotStore } from "../store/voicebotStore";
import { useGeneralStore } from "../store/generalStore";
import { buildTracker, putColor } from "../utils/functionsUtils";
import { Dialogs } from "./dialogs/Dialogs";
import { useFetchEntity } from "../hooks/useEntity";

export const VoicebotAbm = () => {
  const {
    setVoicebot,
    setPreVoicebot,
    setVoicebotRaw,
    setEstados,
    setElements,
    setNodeTrackers,
    setEmpty,
  } = useVoicebotStore((state) => state);

  const { nodeTypes, setNodeTypes } = useNodeTypesStore((state) => state);
  const { entidades, setEntidades } = useGeneralStore((state) => state);

  const fetchNodeTypes = useFetchNodeTypes();
  useEffect(() => {
    if (fetchNodeTypes.data) {
      setNodeTypes(fetchNodeTypes.data);
    }
  }, [fetchNodeTypes.data]);

  /** */
  const _filterUser = {
    pageSize: 8000,
  };

  const [users, setUsers] = useState(null);
  const fetchUsers = useFetchUser(_filterUser);
  useEffect(() => {
    if (fetchUsers.data) {
      setUsers(fetchUsers.data);
    }
  }, [fetchUsers.data]);
  /** */

  const { vnVoicebot: viewName, vfVerVoiceBot: viewFunction } = viewTypes;
  const showVoicebot = ShowView(viewFunction, viewName);
  const userName = localStorage.getItem("userName");

  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customFirst, setCustomFirst] = useState(0);
  const [customers, setCustomers] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [toggleDialog, setToggleDialog] = useState(false);
  const [duplicateDialog, setDuplicateDialog] = useState(false);
  const [asignDialog, setAsignDialog] = useState(false);

  const initParams = {
    rows: 10,
    page: 1,
    sortField: null,
    direction: null,
    nombre: "",
    activo: "",
    tipo: "",
    userId: "",
  };

  const getDirection = (direction) => (direction === 1 ? "ASC" : "DESC");

  const buildFilterVoicebot = (params) => ({
    ...params,
    size: lazyParams.rows,
    field: lazyParams.sortField,
    direction: lazyParams.direction ? getDirection(lazyParams.direction) : null,
  });

  const [lazyParams, setLazyParams] = useState(initParams);
  const [filterVoicebot, setFilterVoicebot] = useState(
    buildFilterVoicebot(initParams)
  );

  useEffect(() => {
    setFilterVoicebot(buildFilterVoicebot(lazyParams));
  }, [lazyParams]);

  const fetchVoicebots = useFetchVoicebot(filterVoicebot);

  useEffect(() => {
    if (fetchVoicebots.data) {
      setTotalRecords(fetchVoicebots.data.pagination.count);
      setCustomers(fetchVoicebots.data.voiceBots);
    }
    setLoading(fetchVoicebots.isLoading);
  }, [fetchVoicebots.data]);

  const toast = useRef(null);

  const pageChange = (event) => {
    setCustomFirst(event.first);
    setLazyParams((previousState) => {
      return {
        ...previousState,
        page: event.page + 1,
        rows: event.rows,
      };
    });
  };

  const openNew = () => {
    setEmpty();
    setVoicebotId(null);
    setTimeout(() => {
      setIsOpen(true);
    }, 8);
  };

  /**------------------ */
  const handlePutColor = (data) => {
    return putColor(data, nodeTypes);
  };

  /** */
  const filtrarOptionsEntidades = (opt) => {
    return opt.map((elemento) => ({
      value: elemento.id,
      label: elemento.display_name,
      options: elemento.options.map((option) => ({
        label: option.option,
        value: option.key,
      })),
    }));
  };

  const _filterEntity = {
    rows: 8000,
  };
  const fetchEntity = useFetchEntity(_filterEntity);
  useEffect(() => {
    if (fetchEntity.data) {
      setEntidades(filtrarOptionsEntidades(fetchEntity.data));
    }
    setLoading(fetchEntity.isLoading);
  }, [fetchEntity.data]);
  /** */

  const handleBuildTracker = (_elements, _estados) => {
    const _nodeTrackers = buildTracker(
      _elements,
      _estados,
      nodeTypes,
      entidades
    );
    setNodeTrackers(_nodeTrackers);
  };

  const [voicebotId, setVoicebotId] = useState(null);
  const {
    data: voicebotData,
    isLoading: voicebotLoading,
    error,
    refetch,
  } = useFetchVoicebotById(voicebotId);

  useEffect(() => {
    if (!voicebotData) return;

    handleBuildTracker(voicebotData.elements, voicebotData.estados);

    setElements(handlePutColor(voicebotData.elements));
    setEstados(voicebotData.estados);

    const _voicebot = {
      id: voicebotData.id,
      userId: voicebotData.userId,
      tipo: voicebotData.tipo,
      activo: voicebotData.activo,
      nombre: voicebotData.nombre,
      firstQuestion: voicebotData.firstQuestion,
    };

    setVoicebotRaw(voicebotData);
    setVoicebot(_voicebot);
  }, [voicebotData]);

  /** */
  const openEdit = (data) => {
    setEmpty();
    setVoicebotId(data.id); // definir el id dispara el fectchbyid
    refetch();
    setTimeout(() => {
      setIsOpen(true);
    }, 8);
  };

  /** */
  const footerPage = (
    <div className="flex-paginator">
      <div></div>
      <div>
        <Paginator
          first={customFirst}
          rows={lazyParams.rows}
          totalRecords={totalRecords}
          rowsPerPageOptions={[5, 10, 20, 50]}
          onPageChange={pageChange}
        ></Paginator>
      </div>
      <div></div>
    </div>
  );

  /** */
  const confirmDeleteVoicebot = (_preVoicebot) => {
    setPreVoicebot(_preVoicebot);
    setDeleteDialog(true);
  };

  const openAsignDialog = (_preVoicebot) => {
    setPreVoicebot(_preVoicebot);
    setAsignDialog(true);
  };

  const confirmToggleVoicebot = (_preVoicebot) => {
    setPreVoicebot(_preVoicebot);
    setToggleDialog(true);
  };

  const openDuplicateDialog = (_preVoicebot) => {
    setPreVoicebot(_preVoicebot);
    setDuplicateDialog(true);
  };
  /** */

  type ErrorResponse = {
    response?: {
      data?: {
        message?: string;
      };
    };
  };
  const queryClient = useQueryClient();

  const mutationCreate = useMutation({
    mutationFn: (voicebot) => {
      return voicebotService.crearVoicebot(
        voicebot,
        userName,
        viewName,
        viewFunction
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["voicebot"] });
      closePanel();
      toast.current.show({
        severity: "success",
        summary: "Alta realizada correctamente",
        life: 3000,
      });
    },
    onError: (error: unknown) => {
      const err = error as ErrorResponse;
      toast.current.show({
        severity: "error",
        summary: "Error en el alta",
        detail: err.response?.data?.message || "Error desconocido",
        life: 3000,
      });
    },
  });

  /** */
  const mutationUpdate = useMutation({
    mutationFn: (voicebot) => {
      return voicebotService.editarVoicebot(
        voicebot,
        userName,
        viewName,
        viewFunction
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["voicebot"] });
      closePanel();
      toast.current.show({
        severity: "success",
        summary: "Cambio realizado correctamente",
        life: 3000,
      });
    },
    onError: (error: unknown) => {
      const err = error as ErrorResponse;
      toast.current.show({
        severity: "error",
        summary: "Error en el cambio",
        detail: err.response?.data?.message || "Error desconocido",
        life: 3000,
      });
    },
  });

  const saveVoicebot = (voicebot) => {
    if (voicebot.id === "") {
      mutationCreate.mutate(voicebot);
    } else {
      mutationUpdate.mutate(voicebot);
    }
  };
  /** */
  const getTemplate = (voicebot) => {
    downloadTemplate(
      voicebot.surveyId,
      voicebot.surveyName,
      userName,
      viewName,
      viewFunction
    );
  };

  /** */
  const [isOpen, setIsOpen] = useState(false);
  const closePanel = () => {
    setIsOpen(false);
  };

  return (
    <>
      {showVoicebot && (
        <div className="card">
          <Toast ref={toast} />

          <VoicebotsTable
            customers={customers}
            initParams={initParams}
            lazyParams={lazyParams}
            setLazyParams={setLazyParams}
            loading={loading}
            footerPage={footerPage}
            openNew={openNew}
            openEdit={openEdit}
            users={users}
            getTemplate={getTemplate}
            confirmDeleteVoicebot={confirmDeleteVoicebot}
            confirmToggleVoicebot={confirmToggleVoicebot}
            openDuplicateDialog={openDuplicateDialog}
            openAsignDialog={openAsignDialog}
          />

          <Dialogs
            users={users}
            deleteDialog={deleteDialog}
            setDeleteDialog={setDeleteDialog}
            duplicateDialog={duplicateDialog}
            setDuplicateDialog={setDuplicateDialog}
            asignDialog={asignDialog}
            setAsignDialog={setAsignDialog}
            toggleDialog={toggleDialog}
            setToggleDialog={setToggleDialog}
          />

          <Panel isOpen={isOpen} closePanel={closePanel}>
            <VoicebotPanelContain
              closePanel={closePanel}
              saveVoicebot={saveVoicebot}
              voicebotLoading={voicebotLoading}
            />
          </Panel>
        </div>
      )}
    </>
  );
};
