import React, { useEffect, useState } from "react";
import { Button, Dialog } from "primereact";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useVoicebotStore } from "../../store/voicebotStore";

export const DuplicateDialog = ({
  users,
  duplicateDialog,
  hideDuplicateDialog,
  rend,
  duplicatePreVoicebot,
}) => {
  const { preVoicebot } = useVoicebotStore((state) => state);

  const formIni = {
    user: "",
    nombre: "",
  };

  const [submit, setSubmit] = useState(false);
  const [formData, setFormData] = useState(formIni);

  useEffect(() => {
    setSubmit(false);
    setFormData(formIni);
  }, [rend]);

  const formatUsers = (users) => {
    return users?.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
  };

  const handleDataChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleDuplicate = () => {
    setSubmit(true);
    if (formData.user !== "" && formData.nombre !== "") {
      duplicatePreVoicebot(formData.user, formData.nombre);
    }
  };

  const duplicateDialogFooter = (
    <>
      <Button
        label="Guardar"
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => handleDuplicate()}
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDuplicateDialog}
      />
    </>
  );

  const headerTemplate = (nombre) => (
    <>
      <div>
        Duplicar voicebot
        <small style={{ color: "#888", fontWeight: "400", marginLeft: "8px" }}>
          {nombre}
        </small>
      </div>
    </>
  );

  return (
    <Dialog
      visible={duplicateDialog}
      header={headerTemplate(preVoicebot.nombre)}
      modal
      footer={duplicateDialogFooter}
      onHide={hideDuplicateDialog}
      style={{ width: "480px", maxWidth: "95%" }}
    >
      <div>
        <div className="field">
          <label htmlFor="usuario">Asignar al usuario</label>
          <Dropdown
            style={{ width: "100%" }}
            value={formData.user}
            options={formatUsers(users)}
            onChange={(e) => handleDataChange("user", e.value)}
            placeholder="Elige un usuario"
            filter
            className={classNames({
              "p-invalid": submit && formData.user === "",
            })}
          />
          {submit && formData.user === "" && (
            <small className="p-error block">Campo requerido</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="nombre">Nombre del nuevo voicebot</label>
          <InputText
            style={{ width: "100%" }}
            name="Nombre nuevo voicebot"
            value={formData.nombre}
            onChange={(e) => handleDataChange("nombre", e.target.value)}
            placeholder="Ingresa el nombre"
            autoComplete="off"
            className={classNames({
              "p-invalid": submit && formData.nombre === "",
            })}
          />
          {submit && formData.nombre === "" && (
            <small className="p-error block">Campo requerido</small>
          )}
        </div>
      </div>
    </Dialog>
  );
};
