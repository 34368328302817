import React, { useEffect, useRef, useState } from "react";
import locutionService from "../../../services/locution.service";
import { viewTypes } from "../../../common/types/views.type";
import { MdPlayArrow, MdStop } from "react-icons/md";

export const AudioPlayer = ({ pregunta }) => {
  const { vnVoicebot: viewName, vfVerVoiceBot: viewFunction } = viewTypes;
  const userName = localStorage.getItem("userName");

  const getIdValue = (value) => {
    const match = value.match(/\/locutions\/([a-f0-9]+)\.wav/);
    return match ? match[1] : null;
  };

  const id = getIdValue(pregunta.value);

  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [loading, setLoading] = useState(true);
  const audioContextRef = useRef<AudioContext | null>(null);
  const audioBufferRef = useRef<AudioBuffer | null>(null);
  const sourceRef = useRef<AudioBufferSourceNode | null>(null);
  const [audioNotFound, setAudioNotFound] = useState(false);

  const maxRetries = 4;

  useEffect(() => {
    let isMounted = true;
    const fetchAudio = async () => {
      setLoading(true);
      setAudioNotFound(false);

      for (let attempt = 0; attempt < maxRetries && isMounted; attempt++) {
        try {
          const response = await locutionService.obtenerAudioFile(
            id,
            userName,
            viewName,
            viewFunction
          );

          if (response && response.data && isMounted) {
            const arrayBuffer = response.data;
            const blob = new Blob([arrayBuffer], {
              type: response.headers["content-type"],
            });
            setAudioBlob(blob);
            setAudioUrl(URL.createObjectURL(blob));
            setLoading(false);
            return;
          } else {
            console.error("No data in response");
            setAudioNotFound(true);
          }
        } catch (error) {
          console.error(
            "Error fetching audio file (attempt " + (attempt + 1) + "):",
            error
          );
          setAudioNotFound(attempt === maxRetries - 1);
        }
      }
      setLoading(false);
    };

    fetchAudio();

    return () => {
      isMounted = false;
      handleStop();
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, [id]);

  useEffect(() => {
    if (audioBlob) {
      const initAudioContext = async () => {
        const arrayBuffer = await audioBlob.arrayBuffer();
        const audioContext = new window.AudioContext();
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

        audioContextRef.current = audioContext;
        audioBufferRef.current = audioBuffer;
      };

      initAudioContext();
    }
  }, [audioBlob]);

  const handlePlay = () => {
    if (audioBufferRef.current && audioContextRef.current) {
      const audioContext = audioContextRef.current;
      const source = audioContext.createBufferSource();
      source.buffer = audioBufferRef.current;
      source.connect(audioContext.destination);
      source.start(0);

      sourceRef.current = source;
      setIsPlaying(true);

      source.onended = () => {
        setIsPlaying(false);
      };
    }
  };

  const handleStop = () => {
    if (sourceRef.current) {
      sourceRef.current.stop();
      sourceRef.current.disconnect();
      sourceRef.current = null;
      setIsPlaying(false);
    }
  };

  const handleDownload = () => {
    if (audioBlob && audioUrl) {
      const link = document.createElement("a");
      link.href = audioUrl;
      link.download = `${id}.wav`;
      link.click();
    }
  };

  return (
    <div className="audio-player">
      {loading ? (
        <div>
          <i className="pi pi-spin pi-spinner" style={{ fontSize: "13px" }}></i>
        </div>
      ) : audioNotFound ? (
        <div>
          <i className="pi pi-ban" style={{ fontSize: "13px" }}></i>
        </div>
      ) : (
        <>
          <button
            onClick={handlePlay}
            disabled={isPlaying}
            style={{ marginRight: "2px" }}
          >
            <MdPlayArrow />
          </button>
          <button
            onClick={handleStop}
            disabled={!isPlaying}
            style={{ paddingLeft: "2px" }}
          >
            <MdStop />
          </button>
        </>
      )}
    </div>
  );
};
