import { useQuery } from "@tanstack/react-query";
import { viewTypes } from "../common/types/views.type";
import entityService from "../services/entity.service";

const fetchEntity = async (filter) => {
  const { vnVoicebot: viewName, vfVerVoiceBot: viewFunction } = viewTypes;
  const userName = localStorage.getItem("userName");

  const response = await entityService.ObtenerEntities(
    filter,
    userName,
    viewName,
    viewFunction
  );
  return response.data.entities;
};

export function useFetchEntity(filter) {
  return useQuery({
    queryKey: ["entities"],
    queryFn: () => fetchEntity(filter),
    retry: false,
    refetchOnWindowFocus: false,
  });
}
