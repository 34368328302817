import React, { useEffect, useState } from "react";
import DynamicVoicebot from "./panelTabs/DynamicVoicebot";
import { useVoicebotStore } from "../../store/voicebotStore";

const PanelConTabs = ({
  cambiarTab,
  tabSeleccionada,
  tabNodeTemplate,
  tabEdgeTemplate,
  setTabsNull,
}) => {
  const { voicebot, panelOpen, setPanelOpen } = useVoicebotStore(
    (state) => state
  );

  useEffect(() => {
    setTab("Voicebot");
  }, [voicebot.id]);

  const setTab = (tab) => {
    if (panelOpen) {
      setTabsNull();
      cambiarTab(tab);
    }
  };

  const togglePanel = () => {
    setPanelOpen(!panelOpen);
  };

  const tabs = ["Voicebot", "Nodo", "Flecha"];

  const getTabStyle = (tab): React.CSSProperties => {
    const isSelected = tabSeleccionada === tab;
    const isVoicebot = tab === "Voicebot";
    const isDisabled = !isVoicebot || (isVoicebot && !panelOpen);
    const isTabDisabled = !panelOpen || isDisabled;

    return {
      backgroundColor: isSelected ? "#2196F3" : "white",
      color: isSelected ? "white" : "#495057",
      borderLeft: isSelected ? "1px solid #2196F3" : "1px solid #ced4da",
      border: isSelected ? "1px solid #2196F3" : "1px solid #ced4da",
      cursor: isTabDisabled ? "unset" : "pointer",
      pointerEvents: isTabDisabled ? "none" : "auto",
    } as React.CSSProperties;
  };

  return (
    <div className={`panel-tabs ${panelOpen ? "open" : "closed"}`}>
      <button className="slide-button" onClick={togglePanel}>
        <i
          className={`pi ${panelOpen ? "pi-chevron-right" : "pi-chevron-left"}`}
        ></i>
      </button>

      <div className="tabs-container">
        {tabs.map((tab) => {
          return (
            <div key={tab} className="tab-wrapper">
              <button
                className="tab"
                onClick={() => tab === "Voicebot" && setTab(tab)}
                style={getTabStyle(tab)}
              >
                {tab}
              </button>
              {!panelOpen && <div className="tab-overlay" />}
            </div>
          );
        })}
      </div>

      <div className="contain">
        {tabSeleccionada === "Nodo" && <div>{tabNodeTemplate}</div>}
        {tabSeleccionada === "Voicebot" && (
          <div>
            <DynamicVoicebot />
          </div>
        )}
        {tabSeleccionada === "Flecha" && <div>{tabEdgeTemplate}</div>}
      </div>
    </div>
  );
};

export default PanelConTabs;
