import React, { useRef } from "react";
import { Toast } from "primereact";
import { DeleteDialog } from "./DeleteDialog";
import { DuplicateDialog } from "./DuplicateDialog";
import { ConfirmationDialog } from "./ConfirmationDialog";
import { AsignDialog } from "./AsignDialog";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import voicebotService from "../../services/voicebot.service";
import { useVoicebotStore } from "../../store/voicebotStore";
import { viewTypes } from "../../common/types/views.type";
import { asignarSurvey, copySurvey } from "../../services/survey.service";

export const Dialogs = ({
  users,
  deleteDialog,
  setDeleteDialog,
  duplicateDialog,
  setDuplicateDialog,
  asignDialog,
  setAsignDialog,
  toggleDialog,
  setToggleDialog,
}) => {
  const { vnVoicebot: viewName, vfVerVoiceBot: viewFunction } = viewTypes;
  const userName = localStorage.getItem("userName");
  const toast = useRef(null);

  const { preVoicebot } = useVoicebotStore((state) => state);
  /** */

  const hideToggleDialog = () => {
    setToggleDialog(false);
  };
  const hideDuplicateDialog = () => {
    setDuplicateDialog(false);
  };
  /** */
  const hideAsignDialog = () => {
    setAsignDialog(false);
  };
  /** */
  const hideDeleteDialog = () => {
    setDeleteDialog(false);
  };

  /** Mutations **/
  type ErrorResponse = {
    response?: {
      data?: {
        message?: string;
      };
    };
  };
  const queryClient = useQueryClient();

  const mutationDel = useMutation({
    mutationFn: (id) => {
      return voicebotService.borrarVoicebot(
        id,
        userName,
        viewName,
        viewFunction
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["voicebot"] });
      hideDeleteDialog();
      toast.current.show({
        severity: "success",
        summary: "Borrar voicebot",
        detail: "Eliminado correctamente",
        life: 3000,
      });
    },
    onError: (error) => {
      const err = error as ErrorResponse;
      toast.current.show({
        severity: "error",
        summary: "Error en el borrado",
        detail: err.response?.data?.message || "Error desconocido",
        life: 3000,
      });
    },
  });

  const deletePreVoicebot = () => {
    const _id = preVoicebot.id;
    mutationDel.mutate(_id);
  };

  /** */
  const mutationToggle = useMutation({
    mutationFn: (id) => {
      return voicebotService.toggleVoicebot(
        id,
        userName,
        viewName,
        viewFunction
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["voicebot"] });
      hideToggleDialog();
    },
    onError: (error) => {
      const err = error as ErrorResponse;
      toast.current.show({
        severity: "error",
        summary: "Error en el cambio",
        detail: err.response?.data?.message || "Error desconocido",
        life: 3000,
      });
    },
  });

  const togglePreVoicebot = () => {
    const _id = preVoicebot.id;
    mutationToggle.mutate(_id);
  };

  /** */
  type AssignParams = {
    id: string;
    user: string;
  };
  const mutationAsign = useMutation({
    mutationFn: (_mutation: AssignParams) => {
      return asignarSurvey(
        userName,
        viewName,
        viewFunction,
        _mutation.id,
        _mutation.user
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["voicebot"] });
      hideAsignDialog();
    },
    onError: (error: unknown) => {
      const err = error as ErrorResponse;
      toast.current.show({
        severity: "error",
        summary: "Error en la asignación",
        detail: err.response?.data?.message || "Error desconocido",
        life: 3000,
      });
    },
  });

  const asignPreVoicebot = (user) => {
    const _mutation: AssignParams = {
      id: preVoicebot.surveyId,
      user: user,
    };
    mutationAsign.mutate(_mutation);
  };
  /** */
  type DuplicateParams = {
    id: string;
    user: string;
    name: string;
  };
  const mutationDuplicate = useMutation({
    mutationFn: (_mutation: DuplicateParams) => {
      return copySurvey(
        userName,
        viewName,
        viewFunction,
        _mutation.id,
        _mutation.user,
        _mutation.name
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["voicebot"] });
      hideDuplicateDialog();
    },
    onError: (error: unknown) => {
      const err = error as ErrorResponse;
      toast.current.show({
        severity: "error",
        summary: "Error en la copia",
        detail: err.response?.data?.message || "Error desconocido",
        life: 3000,
      });
    },
  });

  const duplicatePreVoicebot = (user, nombre) => {
    const _mutation: DuplicateParams = {
      id: preVoicebot.surveyId,
      user: user,
      name: nombre,
    };
    mutationDuplicate.mutate(_mutation);
  };
  /** */

  return (
    <>
      <Toast ref={toast} />
      <DeleteDialog
        deleteDialog={deleteDialog}
        hideDeleteDialog={hideDeleteDialog}
        deletePreVoicebot={deletePreVoicebot}
      />
      <DuplicateDialog
        users={users}
        duplicateDialog={duplicateDialog}
        hideDuplicateDialog={hideDuplicateDialog}
        duplicatePreVoicebot={duplicatePreVoicebot}
        rend={Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000}
      />

      <ConfirmationDialog
        toggleDialog={toggleDialog}
        hideToggleDialog={hideToggleDialog}
        togglePreVoicebot={togglePreVoicebot}
      />

      <AsignDialog
        users={users}
        asignDialog={asignDialog}
        hideAsignDialog={hideAsignDialog}
        asignPreVoicebot={asignPreVoicebot}
        rend={Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000}
      />
    </>
  );
};
