import React from "react";
import { Button, Column, DataTable, Toolbar } from "primereact";
import { FilterPanel } from "./FilterPanel";

export const VoicebotsTable = ({
  customers,
  initParams,
  lazyParams,
  setLazyParams,
  loading,
  footerPage,
  openNew,
  openEdit,
  users,
  getTemplate,
  confirmDeleteVoicebot,
  confirmToggleVoicebot,
  openDuplicateDialog,
  openAsignDialog,
}) => {
  const onSort = (event) => {
    setLazyParams((previousState) => {
      return {
        ...previousState,
        sortField: event.sortField,
        direction: event.sortOrder,
      };
    });
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => openEdit(rowData)}
        />
        <Button
          icon="pi pi-user-edit"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => openAsignDialog(rowData)}
        />
        <Button
          icon="pi pi-copy"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => openDuplicateDialog(rowData)}
        />
        <Button
          icon={rowData.activo ? "pi pi-unlock" : "pi pi-lock"}
          className={
            rowData.activo
              ? "p-button-rounded p-button-success mr-2"
              : "p-button-rounded p-button-danger mr-2"
          }
          onClick={() => confirmToggleVoicebot(rowData)}
        />
        <Button
          icon="pi pi-download"
          className="p-button-rounded p-button-info mr-2"
          onClick={() => getTemplate(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger mr-2"
          onClick={() => confirmDeleteVoicebot(rowData)}
        />
      </div>
    );
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="my-2">
        <div className="fontToolClient">Listado de voicebots</div>
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <Button
        label=""
        icon="pi pi-plus"
        className="p-button-success mr-2"
        onClick={openNew}
      />
    );
  };

  const userBodyTemplate = (rowData) => {
    return <span>{getUser(rowData.userId)}</span>;
  };

  const getUser = (id) => {
    const usuario = users && users.find((u) => u.id === id);
    return usuario ? usuario.name : "-";
  };

  return (
    <>
      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>

      {users && (
        <FilterPanel
          lazyParams={lazyParams}
          setLazyParams={setLazyParams}
          initParams={initParams}
          users={users}
        />
      )}

      <DataTable
        value={customers}
        responsiveLayout="stack"
        dataKey="id"
        stripedRows
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.direction}
        loading={loading}
        emptyMessage="Sin resultados"
        footer={footerPage}
      >
        <Column
          alignHeader="left"
          field="nombre"
          showFilterMenu={false}
          sortable
          header="Nombre"
        />
        <Column
          alignHeader="center"
          field="tipo"
          showFilterMenu={false}
          sortable
          header="Tipo"
          bodyClassName={"centerRowData"}
        />
        <Column
          alignHeader="center"
          field="userId"
          showFilterMenu={false}
          body={userBodyTemplate}
          sortable
          header="Usuario"
          bodyClassName={"centerRowData"}
        />

        <Column
          bodyStyle={{ textAlign: "end", justifyContent: "end" }}
          body={actionBodyTemplate}
          exportable={false}
        />
      </DataTable>
    </>
  );
};
