export const putColor = (data, nodeTypes) => {
  data.forEach((element) => {
    const type = element.data.type;
    if (type !== "00") {
      const matchingNode = nodeTypes.find((nodo) => nodo.type === type);
      if (matchingNode) {
        element.data.color = matchingNode.color;
        element.data.icon = matchingNode.icon;
      }
    }
  });
  return data;
};

export const validarCampos = (element, formArray) => {
  for (const field of formArray) {
    if (field.required) {
      if (
        field.type === "quest" &&
        JSON.stringify(element.data[field.name]) === JSON.stringify({})
      ) {
        return false;
      }
      if (
        !element.data ||
        element.data[field.name] === "" ||
        element.data[field.name] === null ||
        element.data[field.name] === undefined
      ) {
        return false;
      }

      if (field.type === "url") {
        const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
        if (!urlRegex.test(element.data[field.name])) {
          return false;
        }
      }
    }
  }
  return true;
};

const combineFlechas = (f1, f2) => {
  const flechasMap = new Map();
  [...f1, ...f2].forEach((flecha) => {
    flechasMap.set(flecha.value, flecha);
  });
  return Array.from(flechasMap.values());
};

const transformAddedFlechas = (newFlechas) => {
  return newFlechas.map(({ label }) => ({ value: label, name: label }));
};

/** */
export const getTypedData = (nodeTypes, element, typesAllowed) => {
  const nodeType = nodeTypes.find((nodeType) => nodeType.type === element.type);

  let formComponents = [];
  const newElement = [];

  if (nodeType && nodeType.type !== "00") {
    nodeType.form.forEach((group) => {
      formComponents = formComponents.concat(group.components);
    });

    for (const component of formComponents) {
      if (typesAllowed.includes(component.type)) {
        const propName = component.name;
        const propValue = element[propName];
        const typeComponent = component.type;

        newElement.push({
          name: propName,
          value: propValue,
          typeComponent: typeComponent,
        });
      }
    }
  }
  return newElement;
};

const buildFlechas = (data, flechas, entidades, nodeTypes) => {
  let _newFlechas = [];
  const _data = getTypedData(nodeTypes, data, [
    "intention",
    "themes",
    "entities",
  ]);

  _data.forEach((item) => {
    if (item.typeComponent === "intention") {
      if (item.value.multiple) {
        _newFlechas = item.value.multiple;
      } else if (item.value.simple) {
        _newFlechas = [
          {
            label: item.value.simple.label,
            value: item.value.simple.label,
          },
        ];
      }
    } else if (item.typeComponent === "themes") {
      _newFlechas = item.value;
    } else if (item.typeComponent === "entities") {
      const _options = entidades.find(
        (option) => option.value === item.value.value
      ).options;
      _newFlechas = _options;
    }
  });

  if (_newFlechas.length > 0) {
    const transformedNewFlechas = transformAddedFlechas(_newFlechas);
    return combineFlechas(flechas, transformedNewFlechas);
  }
  return flechas;
};

export const buildTracker = (_elements, _estados, nodeTypes, entidades) => {
  let _nodeTrackers = [];

  _elements.forEach((element) => {
    const type = element.data.type;

    if (type !== "00") {
      const _node = nodeTypes.find((node) => node.type === type);
      const _build = buildFlechas(
        element.data,
        _node.flechas,
        entidades,
        nodeTypes
      );

      const flechasConDisabled = _build.map((flecha) => ({
        ...flecha,
        disabled: false,
      }));
      const flechasDisponibles = flechasConDisabled.filter(
        (flecha) => !flecha.disabled
      ).length;

      _estados.forEach((estado) => {
        if (element.data.id === estado.questionId) {
          flechasConDisabled.forEach((flecha) => {
            if (flecha.value === estado.flechita) {
              flecha.disabled = true;
            }
          });
        }
      });

      const node = nodeTypes.find((node) => node.type === element.data.type);
      const formArray = node.form.reduce((acc, curr) => {
        return acc.concat(curr.components);
      }, []);

      const _nodeTracker = {
        id: element.data.id,
        flechas: flechasConDisabled,
        disponibles: flechasDisponibles,
        validForm: validarCampos(element, formArray),
      };

      _nodeTrackers.push(_nodeTracker);
    }
  });
  return _nodeTrackers;
};
