import React, { useEffect, useState } from "react";
import { Button, Dialog } from "primereact";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { useVoicebotStore } from "../../store/voicebotStore";

export const AsignDialog = ({
  users,
  asignDialog,
  hideAsignDialog,
  rend,
  asignPreVoicebot,
}) => {
  const { preVoicebot } = useVoicebotStore((state) => state);

  const formIni = {
    user: preVoicebot.userId,
  };

  const [submit, setSubmit] = useState(false);
  const [formData, setFormData] = useState(formIni);

  useEffect(() => {
    setSubmit(false);
    setFormData(formIni);
  }, [rend]);

  const formatUsers = (users) => {
    return users?.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
  };

  const handleDataChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleDuplicate = () => {
    setSubmit(true);
    if (formData.user !== "") {
      asignPreVoicebot(formData.user);
    }
  };

  const asignDialogFooter = (
    <>
      <Button
        label="Guardar"
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => handleDuplicate()}
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideAsignDialog}
      />
    </>
  );

  const headerTemplate = (nombre) => (
    <>
      <div>
        Asignar usuario a
        <small style={{ color: "#888", fontWeight: "400", marginLeft: "8px" }}>
          {nombre}
        </small>
      </div>
    </>
  );

  return (
    <Dialog
      visible={asignDialog}
      header={headerTemplate(preVoicebot.nombre)}
      modal
      footer={asignDialogFooter}
      onHide={hideAsignDialog}
      style={{ width: "480px", maxWidth: "95%" }}
    >
      <div>
        <div className="field" style={{ marginTop: "24px" }}>
          <Dropdown
            style={{ width: "100%" }}
            value={formData.user}
            options={formatUsers(users)}
            onChange={(e) => handleDataChange("user", e.value)}
            placeholder="Elige un usuario"
            filter
            className={classNames({
              "p-invalid": submit && formData.user === "",
            })}
          />
          {submit && formData.user === "" && (
            <small className="p-error block">Campo requerido</small>
          )}
        </div>
      </div>
    </Dialog>
  );
};
