import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { useVoicebotStore } from "../../../store/voicebotStore";

export const NodeSelect = ({
  nodeItem,
  setNodeItem,
  nodeChildItem,
  setNodeChildItem,
  handleSelectNode,
}) => {
  const { elements, getNodeTracker } = useVoicebotStore((state) => state);

  const [options, setOptions] = useState(null);

  useEffect(() => {
    setOptions(filterNodes);
  }, []);

  const filterNodes = () => {
    const nodes = elements.filter((element) => element.group === "nodes");

    if (nodes.length === 0) {
      return [];
    }

    const formatNodes = nodes.map((node) => {
      const _nodeTracker = getNodeTracker(node.data.id);

      const children = _nodeTracker.flechas.map(
        ({ disabled, ...rest }) => rest
      );

      return {
        name: node.data.name,
        id: node.data.id,
        children: children,
      };
    });

    return formatNodes;
  };

  return (
    <CascadaSelect
      data={options}
      handleSelectNode={handleSelectNode}
      nodeItem={nodeItem}
      setNodeItem={setNodeItem}
      nodeChildItem={nodeChildItem}
      setNodeChildItem={setNodeChildItem}
    />
  );
};

const CascadaSelect = ({
  data,
  handleSelectNode,
  nodeItem,
  setNodeItem,
  nodeChildItem,
  setNodeChildItem,
}) => {
  const handleParentChange = (e) => {
    setNodeItem(e.value);
    setNodeChildItem(null);
  };

  const handleChildChange = (e) => {
    setNodeChildItem(e.value);
    handleSelectNode(nodeItem, e.value);
  };

  return (
    <div className="doble-dropdown">
      <Dropdown
        value={nodeItem}
        options={data}
        onChange={handleParentChange}
        optionLabel="name"
        placeholder="Nodo"
      />

      {nodeItem && (
        <Dropdown
          value={nodeChildItem}
          options={nodeItem.children}
          onChange={handleChildChange}
          optionLabel="name"
          placeholder="Flecha"
        />
      )}
    </div>
  );
};
