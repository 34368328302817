export const viewTypes = {
  vnHome: "HOME",
  vfVerCampanasActivasSalientes: "VER CAMPANAS ACTIVAS SALIENTES",
  vfVerCampanasActivasEntrantes: "VER CAMPANAS ACTIVAS ENTRANTES",
  vfVerCampanasFinalizadas: "VER CAMPANAS FINALIZADAS",

  vnConfiguracion: "CONFIGURACION",
  vfAbmVoicebots: "ABM VOICEBOTS",
  vfAbmCategorias: "ABM CATEGORIAS",
  vfAbmGrupos: "ABM GRUPOS",
  vfAbmLocucion: "ABM LOCUCION",
  vfAbmClientes: "ABM CLIENTES",
  vfAbmUsuarios: "ABM USUARIOS",
  vfAbmAsterisks: "ABM ASTERISKS",
  vfAbmProveedores: "ABM PROVEEDORES",
  vfAbmEntidades: "ABM ENTIDADES",
  vfAbmIntenciones: "ABM INTENCIONES",
  vfAbmBodyWs: "ABM BODY WS",
  vfAbmAnalytics: "ABM ANALYTICS",
  vfAbmLocuciones: "ABM LOCUCIONES",
  vfVerConfiguracion: "VER CONFIGURACION",
  vfAbmGruposUsuarios: "ABM GRUPOS USUARIOS",

  vnListadoCampana: "LISTADO_CAMPANA",
  vfVerReportes: "VER REPORTES",
  vfIrDetalleCampana: "IR DETALLE CAMPANA",
  vfDescargarReportesLis: "DESCARGAR REPORTES",
  vfVerListadoCampana: "VER LISTADO CAMPANA",

  vnDetalleCampana: "DETALLE_CAMPANA",
  vfDescargarReportesDet: "DESCARGAR REPORTES",
  vfIrDetalleLlamado: "IR DETALLE LLAMADO",
  vfControlarCampana: "CONTROLAR CAMPANA",
  vfVerDashborad: "VER DASHBOARD",
  vfVerDetalleCampana: "VER DETALLE CAMPANA",
  vfMostrarCampana: "MOSTRAR CAMPANA",
  vfMostrarAudio: "MOSTRAR AUDIO",
  vfMostrarReprocesamiento: "MOSTRAR REPROCESAMIENTO",
  vfFuncionPlayPauseStop: "FUNCION PLAY, PAUSE, STOP",
  vfVerSeteosCampana: "VER SETEOS CAMPANA",
  vfVerColumnasAvanzadas: "VER COLUMNAS AVANZADAS",
  vfVistaGraficoAvanzado: "VISTA GRAFICO AVANZADO",

  vnDetalleLlamado: "DETALLE_LLAMADO",
  vfVerAudio: "VER AUDIO",
  vfVerAudioCPN: "VER AUDIO C-PLL-NSPC",
  vfVerDetalleLlamado: "VER DETALLE LLAMADO",

  vnMonitoring: "MONITORING",
  vfVerMonitoring: "VER MONITORING",

  vnVoicebot: "VOICEBOT",
  vfVerVoiceBot: "VER VOICEBOT",

  vnCampaignReport: "CAMPAIGN_REPORT",
  vfVerCampaignReport: "VER CAMPAIGN REPORT",
};
