import { useQuery } from "@tanstack/react-query";
import { viewTypes } from "../common/types/views.type";
import bodyService from "../services/body.service";

const fetchBody = async (filter) => {
  const { vnVoicebot: viewName, vfVerVoiceBot: viewFunction } = viewTypes;
  const userName = localStorage.getItem("userName");

  const response = await bodyService.ObtenerBodies(
    filter,
    userName,
    viewName,
    viewFunction
  );
  return response.data.bodies;
};

export function useFetchBody(filter) {
  return useQuery({
    queryKey: ["bodies"],
    queryFn: () => fetchBody(filter),
    retry: false,
    refetchOnWindowFocus: false,
  });
}
